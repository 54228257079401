import { ApolloClient, InMemoryCache } from '@apollo/client';

// const ropstenGraphqlClient = new ApolloClient({
//   uri: process.env.REACT_APP_UNISWAP_ROPSTEN_GRAPHQL_URI,
//   cache: new InMemoryCache(),
//   fetchOptions: {
//     mode: 'no-cors'
//   }
// });
//
// const mainnetGraphqlClient = new ApolloClient({
//   uri: process.env.REACT_APP_UNISWAP_MAINNET_GRAPHQL_URI,
//   cache: new InMemoryCache(),
//   fetchOptions: {
//     mode: 'no-cors'
//   }
// });

// const graphqlClient2 = (uri = process.env.REACT_APP_UNISWAP_ROPSTEN_GRAPHQL_URI) => {
//   if (uri === process.env.REACT_APP_UNISWAP_MAINNET_GRAPHQL_URI) {
//     return mainnetGraphqlClient;
//   }
//
//   return ropstenGraphqlClient;
// };

const graphqlClient = uri => {
  return new ApolloClient({
    uri,
    cache: new InMemoryCache(),
    fetchOptions: {
      mode: 'no-cors'
    }
  })
};

export default graphqlClient;
