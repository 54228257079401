import React from 'react';
// import { Route, Switch, Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from './AsyncFunc';

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      <Component {...props} />
    }
  />
);

// const RestrictRoute = ({ component: Component, isConnectedMetamask, ...rest }) => (
//   <Route
//     {...rest}
//     render={props =>
//       isConnectedMetamask
//         ? <Component {...props} />
//         : <Redirect
//           to={{
//             pathname: '/',
//             state: { from: props.location }
//           }}
//         />
//     }
//   />
// );

const MainRoutes = ({ history, isConnectedMetamask }) => {
  return (
    <Switch>
      <PublicRoute
        exact
        path={'/'}
        component={asyncComponent(() => import('./Containers/Pages/Home'))}
      />
      {/* <PublicRoute
        exact
        path={'/about-us'}
        component={asyncComponent(() => import('./Containers/Pages/NewAbout'))}
      /> */}

      {/* <PublicRoute
        exact
        path={'/terms-and-conditions'}
        component={asyncComponent(() => import('./Containers/Pages/TermsConditions'))}
      />

      <PublicRoute
        exact
        path={'/privacy-policy'}
        component={asyncComponent(() => import('./Containers/Pages/PrivacyPolicy'))}
      />

      <PublicRoute
        exact
        path={'/disclaimers'}
        component={asyncComponent(() => import('./Containers/Pages/Disclaimers'))}
      /> */}
    </Switch>
  );
};

export default MainRoutes;
