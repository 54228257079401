import http from '../services/http';

export default class Coingecko {
  static fetchList() {
    return http.get('https://api.coingecko.com/api/v3/coins/list');
  }

  static fetchChartByCoin(coin, currency, days) {
    return http.get(`https://api.coingecko.com/api/v3/coins/${coin}/market_chart`, {
      params: {
        vs_currency: currency,
        days,
        timestamp: Date.now()
      }
    });
  }

  static fetchChartByTokenAddress(address, currencies, days) {
    return http.get(`https://api.coingecko.com/api/v3/coins/ethereum/contract/${address}/market_chart`, {
      params: {
        vs_currency: currencies,
        days,
        timestamp: Date.now(),
        interval: 'daily'
      }
    });
  }

  static fetchCoinsPrice(coinsId, currencies) {
    return http.get('https://api.coingecko.com/api/v3/simple/price', {
      params: {
        ids: coinsId,
        vs_currencies: currencies
      }
    });
  }

  static fetchCoinsMarketInfo(coinsId, currency) {
    return http.get('https://api.coingecko.com/api/v3/coins/markets', {
      params: {
        vs_currency: currency,
        ids: coinsId
      }
    })
  }

  static fetchCoingeckoTokenInfo(tokenAddress) {
    return http.get(`https://api.coingecko.com/api/v3/coins/ethereum/contract/${tokenAddress}`)
  }

  static fetchEthToBnb() {
    return http.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=bnb');
  }
}
