import localForage from 'localforage';
import { persistReducer } from 'redux-persist';

import user from './user';
import token from './token';
import common from './common';
import coingecko from './coingecko';
import pressMedia from './pressMedia';

const userConfig = {
  key: 'user',
  storage: localForage,
  whitelist: ['userAccount', 'transactionLogs']
};

const commonConfig = {
  key: 'common',
  storage: localForage,
  whitelist: ['contractAbi', 'lockedLiquidity', 'defaultCurrency', 'exchangeRate', 'summaries']
};

const coingeckoConfig = {
  key: 'coingecko',
  storage: localForage,
  whitelist: ['coins']
};

const tokenConfig = {
  key: 'token',
  storage: localForage,
  whitelist: ['trendingCoins']
};


export default {
  user: persistReducer(userConfig, user),
  token: persistReducer(tokenConfig, token),
  common: persistReducer(commonConfig, common),
  coingecko: persistReducer(coingeckoConfig, coingecko),
  pressMedia
};
