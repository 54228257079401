import { handleActions } from 'redux-actions';
import { get, isEmpty } from 'lodash';

const initialState = {
  connectWallet: {
    error: null,
    isConnect: false,
    requesting: false
  },
  userAccount: {
    chainId: '0x1',
    ethBalance: 0,
    accounts: [],
    error: null,
    requesting: false
  },
  lockedTokenInfo: {
    result: [],
    error: null,
    requesting: false
  },
  lockedTokens: {
    result: [],
    error: null,
    requesting: false
  },
  lockTokenProcess: {
    step: 1,
    blockchain: null, // ETH, BSC, AVAX
    tokenType: 'project_token', // project_token, liquidity_token
    selectedToken: null,
    pairInfo: null,
    lockupType: 'founder',
    lockAmount: 0,
    addressList: null,
    addresses: null,
    amounts: null,
    txArr: [],
    amountSum: 0,
    addressCount: 0,
    timeToUnlock: 90,
    timeSelected: 'days',
    approved: false,
    disableApproved: false
  },
  tokenInfoWantToLock: {
    result: null,
    error: null,
    requesting: false
  },
  pairInfo: {
    result: null,
    error: null,
    requesting: false
  },
  approveLockToken: {
    result: null,
    error: null,
    requesting: false
  },
  amountTokenAllowTransfer: {
    result: null,
    error: null,
    requesting: false
  },
  reqLockToken: {
    result: null,
    error: null,
    requesting: false,
    pendingTransactionHash: null,
    showViewEtherscanModal: false,
    showWaitingConfirmModal: false
  },
  reqWithdrawTokens: {
    result: null,
    error: null,
    requesting: false
  },
  lockups: {
    result: [],
    error: null,
    requesting: false
  },
  tokensForLocking: {
    result: [],
    error: null,
    requesting: false
  },
  transactionLogs: {
    result: []
  },
  feeForLockToken: {
    result: null,
    error: null,
    requesting: false
  },
  extendToken: {
    step: 0,
    result: null,
    error: null,
    requesting: false
  },
  transferLock: {
    step: 0,
    result: null,
    error: null,
    requesting: false
  }
};

export const user = handleActions({
  /** SET USER ACCOUNTS **/
  SET_USER_ACCOUNTS: (state, { payload }) => ({
    ...state,
    userAccount: {
      ...state.userAccount,
      chainId: payload.chainId || state.userAccount.chainId,
      accounts: payload.accounts || state.userAccount.accounts,
      ethBalance: payload.balance || state.userAccount.ethBalance
    },
    connectWallet: {
      ...state.connectWallet,
      ...{ isConnect: !isEmpty(payload.accounts) }
    }
  }),
  /** CONNECT WALLET **/
  CONNECT_WALLET_REQUEST: (state) => ({
    ...state,
    connectWallet: {
      ...state.connectWallet,
      requesting: true
    }
  }),
  CONNECT_WALLET_SUCCESS: (state) => ({
    ...state,
    connectWallet: {
      ...state.connectWallet,
      requesting: false,
      isConnect: true,
      error: null
    }
  }),
  CONNECT_WALLET_FAIL: (state, { payload }) => ({
    ...state,
    connectWallet: {
      ...state.connectWallet,
      requesting: false,
      error: payload.error
    }
  }),

  /* FETCH USER LOCKED TOKENS */
  FETCH_USER_LOCKED_TOKENS_REQUEST: (state) => ({
    ...state,
    lockedTokens: {
      ...state.lockedTokens,
      requesting: true
    }
  }),
  FETCH_USER_LOCKED_TOKENS_SUCCESS: (state, { payload }) => ({
    ...state,
    lockedTokens: {
      ...state.lockedTokens,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_USER_LOCKED_TOKENS_FAIL: (state, { payload }) => ({
    ...state,
    lockedTokens: {
      ...state.lockedTokens,
      requesting: false,
      error: payload
    }
  }),

  /* FETCH LOCKED TOKEN INFO */
  FETCH_LOCKED_TOKENS_INFO_REQUEST: (state) => ({
    ...state,
    lockedTokenInfo: {
      ...state.lockedTokenInfo,
      requesting: true
    }
  }),
  FETCH_LOCKED_TOKENS_INFO_SUCCESS: (state, { payload }) => ({
    ...state,
    lockedTokenInfo: {
      ...state.lockedTokenInfo,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_LOCKED_TOKENS_INFO_FAIL: (state, { payload }) => ({
    ...state,
    lockedTokenInfo: {
      ...state.lockedTokenInfo,
      requesting: false,
      error: payload.error
    }
  }),

  /* FETCH PAIR INFO BY SELECTED TOKEN ADDRESS */
  FETCH_PAIR_INFO_BY_SELECTED_TOKEN_ADDR_REQUEST: (state) => ({
    ...state,
    pairInfo: {
      ...state.pairInfo,
      requesting: true
    }
  }),
  FETCH_PAIR_INFO_BY_SELECTED_TOKEN_ADDR_SUCCESS: (state, { payload }) => ({
    ...state,
    pairInfo: {
      ...state.pairInfo,
      requesting: false,
      result: get(payload, 'data.pairInfo'),
      error: null
    },
    lockTokenProcess: {
      ...state.lockTokenProcess,
      pairInfo: get(payload, 'data.pairInfo'),
      totalSupply: get(payload, 'data.totalSupply'),
      userTokenBalance: get(payload, 'data.userTokenBalance')
    }
  }),
  FETCH_PAIR_INFO_BY_SELECTED_TOKEN_ADDR_FAIL: (state, { payload }) => ({
    ...state,
    pairInfo: {
      ...state.pairInfo,
      requesting: false,
      error: payload
    }
  }),

  /* FETCH INFO OF TOKEN WANT TO LOCK */
  FETCH_INFO_OF_TOKEN_WANT_TO_LOCK_REQUEST: (state) => ({
    ...state,
    tokenInfoWantToLock: {
      ...state.tokenInfoWantToLock,
      requesting: true
    }
  }),
  FETCH_INFO_OF_TOKEN_WANT_TO_LOCK_SUCCESS: (state, { payload }) => ({
    ...state,
    tokenInfoWantToLock: {
      ...state.tokenInfoWantToLock,
      requesting: false,
      result: get(payload, 'data.tokenInfo'),
      error: null
    },
    lockTokenProcess: {
      ...state.lockTokenProcess,
      tokenInfo: get(payload, 'data.tokenInfo'),
      pairInfo: get(payload, 'data.uniswapPairInfo')
    }
  }),
  FETCH_INFO_OF_TOKEN_WANT_TO_LOCK_FAIL: (state, { payload }) => ({
    ...state,
    tokenInfoWantToLock: {
      ...state.tokenInfoWantToLock,
      requesting: false,
      error: payload
    }
  }),

  /* GET AMOUNT OF TOKEN ALLOW TRANSFER */
  GET_AMOUNT_TOKEN_ALLOW_TRANSFER_REQUEST: (state) => ({
    ...state,
    amountTokenAllowTransfer: {
      ...state.amountTokenAllowTransfer,
      requesting: true,
      error: null
    }
  }),
  GET_AMOUNT_TOKEN_ALLOW_TRANSFER_SUCCESS: (state, { payload }) => ({
    ...state,
    amountTokenAllowTransfer: {
      ...state.amountTokenAllowTransfer,
      requesting: false,
      result: get(payload, 'data', 0),
      error: null
    },
    lockTokenProcess: {
      ...state.lockTokenProcess,
      lockAmount: get(payload, 'data', 0)
    }
  }),
  GET_AMOUNT_TOKEN_ALLOW_TRANSFER_FAIL: (state, { payload }) => ({
    ...state,
    amountTokenAllowTransfer: {
      ...state.amountTokenAllowTransfer,
      requesting: false,
      error: payload
    }
  }),

  /* APPROVE LOCK TOKEN */
  APPROVE_LOCK_TOKEN_REQUEST: (state) => ({
    ...state,
    approveLockToken: {
      ...state.approveLockToken,
      requesting: true,
      error: null
    }
  }),
  APPROVE_LOCK_TOKEN_SUCCESS: (state, { payload }) => ({
    ...state,
    approveLockToken: {
      ...state.approveLockToken,
      requesting: false,
      result: payload.data,
      error: null
    },
    lockTokenProcess: {
      ...state.lockTokenProcess,
      approved: true
    }
  }),
  APPROVE_LOCK_TOKEN_FAIL: (state, { payload }) => ({
    ...state,
    approveLockToken: {
      ...state.approveLockToken,
      requesting: false,
      error: payload
    }
  }),

  /* SET PENDING LOCK TOKEN TRANSACTION HASH */
  SET_PENDING_LOCK_TOKEN_TRANSACTION_HASH: (state, { payload }) => ({
    ...state,
    reqLockToken: {
      ...state.reqLockToken,
      showWaitingConfirmModal: false,
      pendingTransactionHash: payload.transactionHash,
      showViewEtherscanModal: payload.showViewEtherscanModal
    }
  }),

  SET_VISIBLE_MODAL_WAITING_CONFIRM: (state, { payload }) => ({
    ...state,
    reqLockToken: {
      ...state.reqLockToken,
      showWaitingConfirmModal: payload
    }
  }),

  /* LOCK TOKEN */
  LOCK_TOKEN_REQUEST: (state) => ({
    ...state,
    reqLockToken: {
      ...state.reqLockToken,
      requesting: true,
      showWaitingConfirmModal: true,
      error: null
    }
  }),
  LOCK_TOKEN_SUCCESS: (state, { payload }) => ({
    ...state,
    reqLockToken: {
      ...state.reqLockToken,
      requesting: false,
      result: payload.data,
      pendingTransactionHash: null,
      showViewEtherscanModal: false,
      showWaitingConfirmModal: false,
      error: null
    },
    approveLockToken: {
      result: null,
      requesting: false,
      error: null
    }
  }),
  LOCK_TOKEN_FAIL: (state, { payload }) => ({
    ...state,
    reqLockToken: {
      ...state.reqLockToken,
      requesting: false,
      pendingTransactionHash: null,
      showViewEtherscanModal: false,
      showWaitingConfirmModal: false,
      error: payload
    }
  }),

  /* RESET LOCK TOKEN PROCESS */
  RESET_LOCK_TOKEN_PROCESS: (state, { payload }) => ({
    ...state,
    reqLockToken: {
      result: null,
      requesting: false,
      error: null,
      pendingTransactionHash: null,
      showViewEtherscanModal: false,
      showWaitingConfirmModal: false
    },
    approveLockToken: {
      result: null,
      error: null,
      requesting: false
    },
    lockTokenProcess: {
      ...initialState.lockTokenProcess
    },
    feeForLockToken: {
      ...initialState.feeForLockToken
    },
    amountTokenAllowTransfer: {
      ...initialState.amountTokenAllowTransfer
    }
  }),

  /* WITHDRAW TOKENS */
  WITHDRAW_TOKENS_REQUEST: (state) => ({
    ...state,
    reqWithdrawTokens: {
      ...state.reqWithdrawTokens,
      requesting: true
    }
  }),
  WITHDRAW_TOKENS_SUCCESS: (state, { payload }) => ({
    ...state,
    reqWithdrawTokens: {
      ...state.reqWithdrawTokens,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  WITHDRAW_TOKENS_FAIL: (state, { payload }) => ({
    ...state,
    reqWithdrawTokens: {
      ...state.reqWithdrawTokens,
      requesting: false,
      error: payload
    }
  }),

  /* GET FEE FOR LOCK TOKEN */
  GET_FEE_FOR_LOCK_REQUEST: (state) => ({
    ...state,
    feeForLockToken: {
      ...state.feeForLockToken,
      requesting: true
    }
  }),
  GET_FEE_FOR_LOCK_SUCCESS: (state, { payload }) => ({
    ...state,
    feeForLockToken: {
      ...state.feeForLockToken,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  GET_FEE_FOR_LOCK_FAIL: (state, { payload }) => ({
    ...state,
    feeForLockToken: {
      ...state.feeForLockToken,
      requesting: false,
      error: payload
    }
  }),

  /* FETCH LOCKUP EVENTS OF USER */
  FETCH_LOCKUP_EVENTS_OF_USER_REQUEST: (state) => ({
    ...state,
    lockups: {
      ...state.lockups,
      requesting: true
    }
  }),
  FETCH_LOCKUP_EVENTS_OF_USER_SUCCESS: (state, { payload }) => ({
    ...state,
    lockups: {
      ...state.lockups,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_LOCKUP_EVENTS_OF_USER_FAIL: (state, { payload }) => ({
    ...state,
    lockups: {
      ...state.lockups,
      requesting: false,
      error: payload
    }
  }),

  /* SEARCH TOKEN FOR LOCKING */
  SEARCH_TOKEN_FOR_LOCKING_REQUEST: (state) => ({
    ...state,
    tokensForLocking: {
      ...state.tokensForLocking,
      requesting: true
    }
  }),
  SEARCH_TOKEN_FOR_LOCKING_SUCCESS: (state, { payload }) => ({
    ...state,
    tokensForLocking: {
      ...state.tokensForLocking,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  SEARCH_TOKEN_FOR_LOCKING_FAIL: (state, { payload }) => ({
    ...state,
    tokensForLocking: {
      ...state.tokensForLocking,
      requesting: false,
      error: payload
    }
  }),

  /* CLEAR SEARCH TOKEN FOR LOCKING */
  CLEAR_SEARCH_TOKEN_FOR_LOCKING: (state) => ({
    ...state,
    tokensForLocking: {
      ...initialState.tokensForLocking
    }
  }),

  /* STORE TRANSACTION LOG */
  STORE_TRANSACTION_LOG: (state, { payload }) => ({
    ...state,
    transactionLogs: {
      result: [
        ...state.transactionLogs.result,
        payload
      ]
    }
  }),

  /* CLEAR TRANSACTION LOGS */
  CLEAR_TRANSACTION_LOGS: (state, { payload }) => ({
    ...state,
    transactionLogs: {
      result: payload
    }
  }),


  /* SEARCH TOKEN FOR LOCKING */
  UPDATE_USER_TOKEN_BALANCE_REQUEST: (state) => ({
    ...state
  }),
  UPDATE_USER_TOKEN_BALANCE_SUCCESS: (state, { payload }) => ({
    ...state,
    lockTokenProcess: {
      ...state.lockTokenProcess,
      tokenInfo: {
        ...state.lockTokenProcess.tokenInfo,
        userTokenBalance: payload.data
      }
    }
  }),
  UPDATE_USER_TOKEN_BALANCE_FAIL: (state, { payload }) => ({
    ...state
  }),

  /* SET USER LOCK TOKEN PROCESS */
  SET_USER_LOCK_TOKEN_PROCESS: (state, { payload = {} }) => ({
    ...state,
    lockTokenProcess: {
      ...state.lockTokenProcess,
      ...payload
    }
  }),

  CLEAR_USER_DATA_ON_DISCONNECT_METAMASK: (state) => ({
    ...state,
    lockups: {
      ...initialState.lockups
    },
    lockTokenProcess: {
      ...initialState.lockTokenProcess
    },
    tokenInfoWantToLock: {
      ...initialState.tokenInfoWantToLock
    },
    approveLockToken: {
      ...initialState.approveLockToken
    },
    reqLockToken: {
      ...initialState.reqLockToken
    },
    reqWithdrawTokens: {
      ...initialState.reqWithdrawTokens
    }
  }),
  EXTEND_TOKEN_REQUEST: state => ({
    ...state,
    extendToken: {
      ...state.extendToken,
      requesting: true
    }
  }),
  EXTEND_TOKEN_SUCCESS: (state, { payload }) => ({
    ...state,
    extendToken: {
      ...state.extendToken,
      requesting: false,
      result: payload.data
    }
  }),
  EXTEND_TOKEN_FAIL: (state, { payload }) => ({
    ...state,
    extendToken: {
      ...state.extendToken,
      requesting: false,
      error: payload
    }
  }),
  SET_EXTEND_TOKEN_STEP: (state, { payload }) => ({
    ...state,
    extendToken: {
      ...state.extendToken,
      step: payload
    }
  }),
  TRANSFER_LOCK_TOKEN_REQUEST: state => ({
    ...state,
    transferLock: {
      ...state.transferLock,
      requesting: true
    }
  }),
  TRANSFER_LOCK_TOKEN_SUCCESS: (state, { payload }) => ({
    ...state,
    transferLock: {
      ...state.transferLock,
      requesting: false,
      result: payload.data
    }
  }),
  TRANSFER_LOCK_TOKEN_FAIL: (state, { payload }) => ({
    ...state,
    transferLock: {
      ...state.transferLock,
      requesting: false,
      error: payload
    }
  }),
  SET_TRANSFER_LOCK_TOKEN_STEP: (state, { payload }) => ({
    ...state,
    transferLock: {
      ...state.transferLock,
      step: payload
    }
  })
}, initialState);

export default user;
