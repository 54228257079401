import { filter, get, map, pick, toNumber, uniqBy } from 'lodash';
import { createAction, createActions } from 'redux-actions';

import Api from '../api';

import { etherscanApiUrl, isMainnet, multisenderContractAddress, uniswapApiUri } from '../utils/common';

/** SET ABI **/
export const setContractAbi = createAction('SET_CONTRACT_ABI');
export const changeViewLockup = createAction('CHANGE_VIEW_LOCKUP');

/** FETCH CONTRACT ABI **/
const { fetchContractAbiRequest, fetchContractAbiSuccess, fetchContractAbiFail } = createActions({
  FETCH_CONTRACT_ABI_REQUEST: () => { },
  FETCH_CONTRACT_ABI_SUCCESS: data => ({ data }),
  FETCH_CONTRACT_ABI_FAIL: error => ({ error }),
});

export const fetchContractAbi = () => (dispatch) => {
  dispatch(fetchContractAbiRequest());

  const chainId = get(window, 'ethereum.chainId');
  const url = etherscanApiUrl(chainId);
  const contractAddress = multisenderContractAddress(chainId);

  return Api.Common.fetchContractAbi(url, contractAddress).then(response => {
    const data = get(response, 'data.result', []);
    return dispatch(fetchContractAbiSuccess(typeof data === 'string' ? JSON.parse(data) : data));
  }).catch(error => {
    return dispatch(fetchContractAbiFail(error));
  });
};

/** SEARCH TOKEN IN UNISWAP **/
const { searchTokenInUniswapRequest, searchTokenInUniswapSuccess, searchTokenInUniswapFail } = createActions({
  SEARCH_TOKEN_IN_UNISWAP_REQUEST: () => { },
  SEARCH_TOKEN_IN_UNISWAP_SUCCESS: data => ({ data }),
  SEARCH_TOKEN_IN_UNISWAP_FAIL: error => ({ error }),
});

export const searchTokenInUniswap = (queryString) => (dispatch) => {
  dispatch(searchTokenInUniswapRequest());

  const chainId = get(window, 'ethereum.chainId');
  const url = uniswapApiUri(chainId);

  return Api.Common.searchTokenInUniswaps(queryString, url).then(response => {
    const asName = get(response, 'data.asName', []);
    const asAddress = get(response, 'data.asAddress');
    const asSymbol = get(response, 'data.asSymbol', []);

    const result = uniqBy([...asName, ...asAddress, ...asSymbol], 'id');
    return dispatch(searchTokenInUniswapSuccess(result));
  }).catch(error => {
    return dispatch(searchTokenInUniswapFail(error));
  });
};

/** FETCH ETH PRICE **/
const { fetchEthPriceRequest, fetchEthPriceSuccess, fetchEthPriceFail } = createActions({
  FETCH_ETH_PRICE_REQUEST: () => { },
  FETCH_ETH_PRICE_SUCCESS: data => ({ data }),
  FETCH_ETH_PRICE_FAIL: error => ({ error }),
});

export const fetchEthPrice = () => (dispatch) => {
  dispatch(fetchEthPriceRequest());

  const chainId = get(window, 'ethereum.chainId') || '0x1';
  const url = uniswapApiUri(chainId);

  return Api.Common.fetchEthPrice(url).then(response => {
    const data = get(response, 'data.bundle.ethPrice');

    return dispatch(fetchEthPriceSuccess(data));
  }).catch(error => {
    return dispatch(fetchEthPriceFail(error));
  });
};

/** STORE TRANSACTION **/
const { storeTransactionRequest, storeTransactionSuccess, storeTransactionFail } = createActions({
  STORE_TRANSACTION_REQUEST: () => { },
  STORE_TRANSACTION_SUCCESS: data => ({ data }),
  STORE_TRANSACTION_FAIL: error => ({ error }),
});

export const storeTransaction = (data) => (dispatch) => {
  dispatch(storeTransactionRequest());

  const chainId = get(window, 'ethereum.chainId');

  return Api.Common.storeTransaction(chainId, data).then(response => {
    const data = get(response, 'data');

    return dispatch(storeTransactionSuccess(data));
  }).catch(error => {
    return dispatch(storeTransactionFail(error));
  });
};

/** FETCH TOKENS PRICE **/
const { fetchTokensPriceByAddressRequest, fetchTokensPriceByAddressSuccess, fetchTokensPriceByAddressFail } = createActions({
  FETCH_TOKENS_PRICE_BY_ADDRESS_REQUEST: () => { },
  FETCH_TOKENS_PRICE_BY_ADDRESS_SUCCESS: data => ({ data }),
  FETCH_TOKENS_PRICE_BY_ADDRESS_FAIL: error => ({ error }),
});

export const fetchTokensPriceByAddress = (tokenAddresses, currencies) => (dispatch) => {
  dispatch(fetchTokensPriceByAddressRequest());

  return Api.Common.fetchTokensPriceByAddress(tokenAddresses, currencies).then(response => {
    const data = get(response, 'data');
    return dispatch(fetchTokensPriceByAddressSuccess(data));
  }).catch(error => {
    return dispatch(fetchTokensPriceByAddressFail(error));
  });
};


/** SEARCH TOKENS LOCKED WITH TEAM **/
const { searchTokensLockedWithTeamRequest, searchTokensLockedWithTeamSuccess, searchTokensLockedWithTeamFail } = createActions({
  SEARCH_TOKENS_LOCKED_WITH_TEAM_REQUEST: () => { },
  SEARCH_TOKENS_LOCKED_WITH_TEAM_SUCCESS: (data, meta) => ({ data, meta }),
  SEARCH_TOKENS_LOCKED_WITH_TEAM_FAIL: error => ({ error }),
});

export const searchTokensLockedWithTeam = ({ page = 1, limit = 10, q = '', currency = 'usd', sortOption }) => (dispatch, getState) => {
  dispatch(searchTokensLockedWithTeamRequest());

  const chainId = get(window, 'ethereum.chainId') || '0x1';
  const network = isMainnet(chainId) ? 'mainnet' : 'testnet';

  return Api.Common.searchTokensLockedWithTeam(network, page, limit, q, sortOption).then(async ({ data }) => {
    const meta = pick(data, ['page', 'per_page', 'total', 'total_pages']);
    const tokenDatas = get(data, 'data', []);

    const result = [];
    tokenDatas.forEach((item, index) => {
      result.push({
        ...item,
        key: get(meta, 'page', 1) * get(meta, 'per_page', limit) + index,
      });
    });
    return dispatch(searchTokensLockedWithTeamSuccess(result, meta));
  }).catch(error => {
    return dispatch(searchTokensLockedWithTeamFail(error));
  });
};


/** FETCH TOKENS LOCKED SUMMARY **/
const { fetchTokensLockedSummaryRequest, fetchTokensLockedSummarySuccess, fetchTokensLockedSummaryFail } = createActions({
  FETCH_TOKENS_LOCKED_SUMMARY_REQUEST: () => { },
  FETCH_TOKENS_LOCKED_SUMMARY_SUCCESS: data => ({ data }),
  FETCH_TOKENS_LOCKED_SUMMARY_FAIL: error => ({ error }),
});

export const fetchTokensLockedSummary = () => (dispatch) => {
  dispatch(fetchTokensLockedSummaryRequest());

  const chainId = get(window, 'ethereum.chainId') || '0x1';
  const uniswapApiUrl = uniswapApiUri(chainId);

  return Api.Common.fetchTokensLockedSummary(chainId).then(async response => {
    const data = get(response, 'data');
    const totalProjectLocked = get(data, 'totalProject', 0);
    const summaryData = get(data, 'summaryData', []);
    const filterAmountZero = filter(summaryData, item => Number(get(item, 'tokensLocked', 0)) > 0);
    const addresses = map(filterAmountZero, item => item.address);
    const lowerCaseAddrs = map(addresses, item => item.toLowerCase());

    // const price = await Api.Common.fetchTokensPriceByAddress(joinAddresses, 'usd');
    let coinsPrice = {};
    try {
      for (let i = 0; i < lowerCaseAddrs.length; i = i + 150) {
        const addresses = lowerCaseAddrs.slice(i, i + 150);
        let { data: coinsPricePiece } = await Api.Common.fetchTokensPriceByAddress(addresses, 'usd');
        coinsPrice = {
          ...coinsPrice,
          ...coinsPricePiece,
        };
      }
    } catch (e) {
    }

    const priceData = { ...coinsPrice };

    const ethPrice = await Api.Common.fetchEthPrice(uniswapApiUrl);
    const uniswapPrice = await Api.Common.fetchTokensPriceFromUniswap(lowerCaseAddrs, uniswapApiUrl);

    return dispatch(fetchTokensLockedSummarySuccess({
      priceData,
      totalProjectLocked,
      summary: filterAmountZero,
      uniswapPrice: get(uniswapPrice, 'data.tokens'),
      ethPrice: get(ethPrice, 'data.bundle.ethPrice'),
    }));
  }).catch(error => {
    console.log('error:', error);
    return dispatch(fetchTokensLockedSummaryFail(error));
  });
};

/** FETCH LOCKED LIQUIDITY VALUE **/
const { fetchLockedLiquidityValueRequest, fetchLockedLiquidityValueSuccess, fetchLockedLiquidityValueFail } = createActions({
  FETCH_LOCKED_LIQUIDITY_VALUE_REQUEST: () => { },
  FETCH_LOCKED_LIQUIDITY_VALUE_SUCCESS: data => ({ data }),
  FETCH_LOCKED_LIQUIDITY_VALUE_FAIL: error => ({ error }),
});

export const fetchLockedLiquidityValue = () => (dispatch) => {
  dispatch(fetchLockedLiquidityValueRequest());

  return Api.Common.fetchLockedLiquidityValue().then(({ data }) => {
    return dispatch(fetchLockedLiquidityValueSuccess(data));
  }).catch(error => {
    return dispatch(fetchLockedLiquidityValueFail(error));
  });
};

export const setOverlapMinHeight = createAction('SET_OVERLAP_MIN_HEIGHT');

export const fetchExchangeRateData = createAction('FETCH_EXCHANGE_RATE_DATA');

export const fetchExchangeRate = () => (dispatch) => {
  return Api.Common.fetchExchangeRate().then(({ data }) => {
    const eur = get(data, 'USD_EUR', 1);
    return dispatch(fetchExchangeRateData({ eur }));
  }).catch(error => {
    return dispatch(fetchExchangeRateData({ error }));
  });
};

export const setDefaultCurrency = createAction('SET_DEFAULT_CURRENCY');

export const exchangeRate = amount => (dispatch, getState) => {
  const defaultCurrency = getState().common.defaultCurrency;
  const exchangeRate = getState().common.exchangeRate[defaultCurrency];
  if (exchangeRate) {
    amount = toNumber(amount) * exchangeRate;
  }
  let symbol = '$';
  if (defaultCurrency === 'eur') {
    symbol = '€';
  }
  return {
    symbol,
    amount,
    str: `${symbol}${amount}`,
  };
};


/** FETCH LOCKED LIQUIDITY VALUE **/
const { fetchSummariesRequest, fetchSummariesSuccess, fetchSummariesFail } = createActions({
  FETCH_SUMMARIES_REQUEST: () => { },
  FETCH_SUMMARIES_SUCCESS: data => ({ data }),
  FETCH_SUMMARIES_FAIL: error => ({ error }),
});

export const fetchSummaries = () => (dispatch) => {
  dispatch(fetchSummariesRequest());
  const chainId = get(window, 'ethereum.chainId');
  const network = isMainnet(chainId) ? 'mainnet' : 'testnet';

  return Api.Common.fetchSummaries(network).then(({ data }) => {
    return dispatch(fetchSummariesSuccess(data));
  }).catch(error => {
    return dispatch(fetchSummariesFail(error));
  });
};
