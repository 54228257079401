import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';

import { Button, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { get, isEmpty } from 'lodash';

import { connectMetamask, connectCoinbaseWallet, connectToFortmaticWallet, connectToPortisWallet } from '../../../actions/user';

import ModalWrapper from './styles';

class ModalSelectWallet extends Component {
  handleClickBtnConnectWallet = () => {
    const { toggleModalSelectWallet } = this.props;
    toggleModalSelectWallet(false);

    const { userAccountState } = this.props;
    const accounts = get(userAccountState, 'accounts');

    if (!isEmpty(accounts)) {
      return accounts;
    }

    this.handleConnectMetamask();
  };

  handleConnectMetamask = (path) => {
    const { connectMetamask, history } = this.props;

    connectMetamask().then(() => {
      const { connectWalletState } = this.props;
      const isConnect = get(connectWalletState, 'isConnect');
      if (isConnect && path) {
        return history.push(path);
      }

      const connectError = get(connectWalletState, 'error');

      if (connectError) {
        const errorCode = get(connectError, 'code', '');
        if (errorCode.toString() === '-32002') {
          return alert('Please login to Metamask');
        }

        // TODO Make popup ask user install extension
        return alert('Please install Metamask extension!');
      }
    });
  };

  handleConnectCoinbaseWallet = () => {
    const { toggleModalSelectWallet } = this.props;
    toggleModalSelectWallet(false);
    const { userAccountState, connectCoinbaseWallet } = this.props;
    const accounts = get(userAccountState, 'accounts');

    if (!isEmpty(accounts)) {
      this.setState({ isVisibleMyAccoutModal: true, isToggle: false });
      return accounts;
    }

    connectCoinbaseWallet();
  };

  handleConnectToFortmatic = () => {
    const { toggleModalSelectWallet } = this.props;
    toggleModalSelectWallet(false);
    const { userAccountState, connectToFortmaticWallet } = this.props;
    const accounts = get(userAccountState, 'accounts');

    if (!isEmpty(accounts)) {
      this.setState({ isVisibleMyAccoutModal: true, isToggle: false });
      return accounts;
    }

    connectToFortmaticWallet();
  };

  handleConnectToPortis = () => {
    const { toggleModalSelectWallet } = this.props;
    toggleModalSelectWallet(false);
    const { userAccountState, connectToPortisWallet } = this.props;
    const accounts = get(userAccountState, 'accounts');

    if (!isEmpty(accounts)) {
      this.setState({ isVisibleMyAccoutModal: true, isToggle: false });
      return accounts;
    }

    connectToPortisWallet();
  };

  render() {
    const { visibleModalSelectWallet, toggleModalSelectWallet } = this.props;

    const data = [
      {
        key: 'metamask',
        label: 'MetaMask',
        imgSrc: `${process.env.PUBLIC_URL}/assets/connects/metamask@3x.png`,
        onClick: this.handleClickBtnConnectWallet,
        disabled: false
      },
      /*{
        key: 'walletConnect',
        label: 'WalletConnect (coming soon)',
        imgSrc: `${process.env.PUBLIC_URL}/assets/connects/walletConnect@3x.png`,
        onClick: () => { },
        disabled: true
      },
      {
        key: 'coinbase',
        label: 'Coinbase Wallet',
        imgSrc: `${process.env.PUBLIC_URL}/assets/connects/coinbase@3x.png`,
        onClick: this.handleConnectCoinbaseWallet,
        disabled: false
      },
      {
        key: 'fortmatic',
        label: 'Fortmatic',
        imgSrc: `${process.env.PUBLIC_URL}/assets/connects/fortmatic@3x.png`,
        onClick: this.handleConnectToFortmatic,
        disabled: false
      },
      {
        key: 'portis',
        label: 'Portis',
        imgSrc: `${process.env.PUBLIC_URL}/assets/connects/portis@3x.png`,
        onClick: this.handleConnectToPortis,
        disabled: false
      }*/
    ];


    return (
      <Modal
        closable={false}
        visible={visibleModalSelectWallet}
        onCancel={() => toggleModalSelectWallet(false)}
        footer={null}
        className="antd-modal-content-border-15"
      >
        <ModalWrapper>
          <div className="row-between">
            <p className="title">Connect to a wallet</p>
            <Button
              className="btn-close"
              ghost
              icon={<CloseOutlined style={{ color: '#32737e', fontSize: 20, fontWeight: 'bold' }} />}
              onClick={() => toggleModalSelectWallet(false)}
            />
          </div>
          <div className="container-modal">
            {
              data.map((item, index) => (
                <Button
                  key={item.key}
                  className={`${item.key} btn row-between`}
                  onClick={item.onClick}
                  disabled={item.disabled}
                >
                  <p style={{ color: item.disabled ? 'rgba(51, 51, 51, 0.5)' : '#333333' }}>{item.label}</p>
                  <img src={item.imgSrc} alt={item.key} />
                </Button>
              ))
            }
          </div>
        </ModalWrapper>
      </Modal>
    )
  }
}


export default connect(
  state => ({
    userAccountState: state.user.userAccount,
    connectWalletState: state.user.connectWallet
  }),
  dispatch => bindActionCreators({
    connectMetamask,
    connectCoinbaseWallet,
    connectToPortisWallet,
    connectToFortmaticWallet
  }, dispatch)
)(ModalSelectWallet);
