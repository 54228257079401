import http from '../services/http';

export default class PressMedia {
  static fetchPresses() {
    return http.get('/presses', {
      params: {
        filter: {
          include: ['image'],
          order: 'dateRelease DESC'
        }
      }
    });
  }
}
