import { handleActions } from 'redux-actions';
import moment from 'moment';

const initialState = {
  coins: {
    result: [],
    error: null,
    requesting: false,
    time: null
  },
  chart: {
    result: [],
    error: null,
    requesting: false,
    time: null
  },
  coingeckoTokenInfo: {
    result: null,
    error: null,
    requesting: false
  },
  ethToBnb: {
    result: null,
    error: null,
    requesting: false
  }
};

export const coingecko = handleActions({
  /* FETCH COINS */
  FETCH_COINS_REQUEST: (state) => ({
    ...state,
    coins: {
      ...state.coins,
      requesting: true
    }
  }),
  FETCH_COINS_SUCCESS: (state, { payload }) => ({
    ...state,
    coins: {
      ...state.coins,
      requesting: false,
      result: payload.data,
      error: null,
      time: moment().valueOf()
    }
  }),
  FETCH_COINS_FAIL: (state, { payload }) => ({
    ...state,
    coins: {
      ...state.coins,
      requesting: false,
      error: payload.error
    }
  }),

  /* FETCH CHART */
  FETCH_CHART_BY_COIN_REQUEST: (state) => ({
    ...state,
    chart: {
      ...state.chart,
      requesting: true
    }
  }),
  FETCH_CHART_BY_COIN_SUCCESS: (state, { payload }) => ({
    ...state,
    chart: {
      ...state.chart,
      requesting: false,
      result: payload.data,
      error: null,
      time: moment().valueOf()
    }
  }),
  FETCH_CHART_BY_COIN_FAIL: (state, { payload }) => ({
    ...state,
    chart: {
      ...state.chart,
      requesting: false,
      error: payload.error
    }
  }),

  /*  FETCH CHART BY TOKEN ADDRESS */
  FETCH_CHART_BY_TOKEN_ADDRESS_REQUEST: (state) => ({
    ...state,
    chart: {
      ...state.chart,
      requesting: true
    }
  }),
  FETCH_CHART_BY_TOKEN_ADDRESS_SUCCESS: (state, { payload }) => ({
    ...state,
    chart: {
      ...state.chart,
      requesting: false,
      result: payload.data,
      error: null,
      time: moment().valueOf()
    }
  }),
  FETCH_CHART_BY_TOKEN_ADDRESS_FAIL: (state, { payload }) => ({
    ...state,
    chart: {
      ...state.chart,
      requesting: false,
      error: payload.error
    }
  }),


   /* FETCH COINGECKO TOKEN PROFILE */
   FETCH_COINGECKO_TOKEN_INFO_REQUEST: (state) => ({
    ...state,
    coingeckoTokenInfo: {
      ...state.coingeckoTokenInfo,
      requesting: true
    }
  }),
  FETCH_COINGECKO_TOKEN_INFO_SUCCESS: (state, { payload }) => ({
    ...state,
    coingeckoTokenInfo: {
      ...state.coingeckoTokenInfo,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_COINGECKO_TOKEN_INFO_FAIL: (state, { payload }) => ({
    ...state,
    coingeckoTokenInfo: {
      ...state.coingeckoTokenInfo,
      requesting: false,
      error: payload.error
    }
  }),


   /* FETCH_ETH_TO_BNB_REQUEST */
   FETCH_ETH_TO_BNB_REQUEST: (state) => ({
    ...state,
    ethToBnb: {
      ...state.ethToBnb,
      requesting: true
    }
  }),
  FETCH_ETH_TO_BNB_SUCCESS: (state, { payload }) => ({
    ...state,
    ethToBnb: {
      ...state.ethToBnb,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_ETH_TO_BNB_FAIL: (state, { payload }) => ({
    ...state,
    ethToBnb: {
      ...state.ethToBnb,
      requesting: false,
      error: payload.error
    }
  })

}, initialState);

export default coingecko;
