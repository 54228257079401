import React, { Component } from 'react';

import Wrapper from './styles';

const data = chainId => {
  const options = {
    '0x1': {
      value: 'Ethereum Mainnet',
      color: '#00d596',
    },
    '0x5': {
      value: 'Ethereum Goerli',
      color: '#AF315D',
    },
    '0xaa36a7': {
      value: 'Ethereum Sepolia',
      color: '#AF315D',
    },
    '0x38': {
      value: 'BSC Mainnet',
      color: '#00d596',
    },
    '0x61': {
      value: 'BSC Testnet',
      color: '#AF315D',
    },
    '0xa86a': {
      value: 'AVAX Mainnet',
      color: '#00d596',
    },
    '0xa869': {
      value: 'AVAX Testntet',
      color: '#AF315D',
    },
  };
  return options[chainId] || options['0x1'];
};

const supportChainId = [
  {
    chainId: '0x1',
    value: 'Ethereum Mainnet',
    color: '#00d596',
  },
  {
    chainId: '0x5',
    value: 'Ethereum Goerli',
    color: '#AF315D',
  },
  {
    chainId: '0xaa36a7',
    value: 'Ethereum Sepolia',
    color: '#AF315D',
  },
  {
    chainId: '0x38',
    value: 'BSC Mainnet',
    color: '#00d596',
  },
  {
    chainId: '0x61',
    value: 'BSC Testnet',
    color: '#AF315D',
  },
  {
    chainId: '0xa86a',
    value: 'AVAX Mainnet',
    color: '#00d596',
  },
  {
    chainId: '0xa869',
    value: 'AVAX Testntet',
    color: '#AF315D',
  },
];

class DropdownFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chainId: window.ethereum ? window.ethereum.chainId : '0x1',
      activeDropDown: false,
    };
  }

  componentDidMount() {
    window.ethereum && window.ethereum.on('chainChanged', async (chainId) => {
      this.setState({ chainId });
    });
  }

  handleOnClick = chainId => () => {
    this.setState({
      chainId,
      activeDropDown: !this.state.activeDropDown,
    });
  };

  toggle = () => {
    return this.setState({ activeDropDown: !this.state.activeDropDown });
  };

  render() {
    const { chainId, activeDropDown } = this.state;
    const optionValue = data(chainId);
    return (
      <Wrapper activeDropDown={activeDropDown}>
        <div className="dropdown">
          <div style={{ display: 'flex', minWidth: 80, padding: '5px 10px' }}>
            <div className="circle" style={{ backgroundColor: optionValue.color }} />
            <p>{optionValue.value}</p>
            {/* <div>
              <img className="icon" src={`${process.env.PUBLIC_URL}/assets/footer/dropdown@3x.png`} alt="" />
            </div> */}
          </div>

          <div className="dropdown-content">
            {
              supportChainId.map(e => {
                return (
                  <div style={{ display: 'flex', marginBottom: 5 }} key={e.chainId}>
                    <div className="circle" style={{ backgroundColor: e.color }} />
                    <p onClick={this.handleOnClick(e.chainId)}>{e.value}</p>
                  </div>
                );
              })
            }
          </div>
          {/* <div className="example" /> */}
        </div>
      </Wrapper>
    );
  }
}

export default DropdownFooter;
