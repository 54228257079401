import styled from 'styled-components';
import WithDirection from '../../withDirection';

const Wrapper = styled.div`
  background-color: transparent;
  border: 1px solid #04E9BC;
  border-radius: 5px;
  cursor: pointer;
  .circle {
    height: 8px;
    width: 8px;
    margin-right: 7px;
    background-color: #00d596;
    border-radius: 50%;
    align-self: center;
  }
  .dropdown {
    position: relative;
    display: flex;
    justify-content: center;
    align-items; center;
    flex-direction: row;
    min-width: 120px;
    position: relative;
    height: 100%;
    
    p {
      margin: auto 0;
      color: #04E9BC;
    }

    .icon {
      height: 10px;
      width: 10px;
      object-fit: contain;
    }
  }
  
  .dropdown-content {
    position: absolute;
    transform: ${props => props.activeDropDown ? 'translateY(-100%)' : 'translateY(0)'};
    z-index: ${props => props.activeDropDown ? 1 : -1};
    transition: transform 0.4s;
    background-color: #f3f7fa;
    min-width: 120px;
    padding: 12px 16px 5px 20px;
    cursor: pointer;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    p:hover {
      color: #00d596;
    }
  }
`;

export default WithDirection(Wrapper);
