import styled from 'styled-components';
import WithDirection from '../../withDirection';

//background-image: ${props => props.isBlack ? 'linear-gradient(#000, #000)' : 'linear-gradient(0deg,rgba(255,187,0,.8549019607843137),#ffb900)'};

const NavWrapper = styled.div`
  background-color: ${props => props.isBlack ? '#000000' : '#0c1228'};
  width: 100%;
  #overlap {
    height: 225px;
  }

  .btn-account-profile {
    display: flex;
    font-family: "Helvetica Neue", Roboto;
    color: #04E9BC;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 10px;
    align-items: center;
    border: 1px solid #04E9BC;

    @media only screen and (max-width: 1044px) {
      padding: 5px 10px;
    }

    // &:hover, &:focus, &:active {
    //   border-color: transparent;
    //   outline: none;
    // }
  }

  .balance-wrapper {
    background-color: #054c58;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .account-addr-wrapper {
    background-color: transparent;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    
    .account-addr {
      margin-right: 8px;
    }
  }

  .dropdown-menu {
    width: 100%;
    padding: 5px 15px;
    background-color: #ffffff;
    position: fixed;
    transform: ${props => props.isToggle ? 'translateY(0)' : 'translateY(-120%)'};
    opacity: ${props => props.isToggle ? 1 : 0};
    transition: transform .2s, opacity .4s;
    z-index: 9999;
    top: 0;
    .nav-li {
      display: flex;
      justify-content: center;
      opacity: 0.5;
      a {
        font-size: 12px;
        color: white;
        font-weight: 600;
      }
    }
    .nav-li-mobile {
      display: flex;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #F7F7F7;

      a {
        font-size: 14px;
        color: #000;
        font-weight: 600;
      }
      .logo {
        width: 12px;
        object-fit: contain;
        margin-right: 5px;
      }
    }
    .btn-connect-wallet {
      padding: 10px 15px;
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      border-radius: 4px;
      margin: 20px 0;
      background-color: #1961D4;
    }

    .profile-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 25px 15px;
    }
  }

  .visibility {
    visibility: visible;
  }

  .hidden {
    visibility: hidden;
  }

  .nav-fixed {
    width: 100%;
    background-color: #026d58;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;

    .scrolled-nav-container {
      padding: 20px 0;
      max-width: 1200px;
      margin: auto;

      @media only screen and (max-width: 1255px) {
        padding: 30px 3%;
      }
  
      .nav-left {
        .logo {
          width: auto;
          height: 39px;
          object-fit: contain;
        }
      }
  
      .nav-center {
        .nav-ul {
          justify-content: center;
          .nav-li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            opacity: 1;
            margin: 0 15px;
            text-align: center;
            img {
              width: auto;
              height: 15px;
              object-fit: contain;
              margin-right: 7px;
            }
            a {
              font-family: 'Helvetica Neue', 'Roboto';
              font-size: 18px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.07px;
              color: inherit;
            }

            a:hover {
              color: #ffba08;
            }
          }
  
          .nav-li:hover {
            cursor: pointer;
            color: #ffba08;
          }
        }
      }
  
      .nav-right {
        display: flex;
        justify-content: flex-end;
  
        @media only screen and (max-width: 768px) {
          display: none;
        }
        @media only screen and (max-width: 992px) {
          display: none;
        }
  
        .btn-connect {
          background-color: transparent;
          padding: 6px 15px;
          border-radius: 5px;
          border: 1px solid;
          font-family: "Helvetica Neue", Roboto;
          font-size: 14px;
          letter-spacing: 0.28px;
          color: #04E9BC;
          margin: 0;
        }
        .btn-connect:hover {
          opacity: 0.5;
        }

      }
  
      .icon-container {
        display: none;
        justify-content: flex-end;
  
        .icon {
          display: none;
  
          @media (max-width: 768px) {
            display: block;
            font-size: 25px;
            color: black;
          }
        }
  
        .icon-collapse {
          animation: flipInX .2s ease-in;
        }
        .icon-collapse-out {
          animation: flipOutX .2s ease-in;
        }
      }

      @media only screen and (max-width: 992px){
        .icon-container {
          display: flex;
          .icon {
            display: block;
            font-size: 25px;
            color: black;
          }
        }
      }
    }
  }

  .nav-container {
    padding: 20px 0;
    max-width: 1200px;
    margin: auto;
    justify-content: space-between;

    @media only screen and (max-width: 1255px) {
      padding: 30px 3%;
    }

    .nav-left {
      .logo {
        width: auto;
        height: 39px;
        object-fit: contain;
      }
    }

    .nav-center {
      .nav-ul {
        justify-content: center;
        .nav-li {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          opacity: 0.5;
          margin: 0 15px;
          // min-width: 105px;
          text-align: center;
          img {
            width: auto;
            height: 15px;
            object-fit: contain;
            margin-right: 7px;
          }
          a {
            font-family: 'Helvetica Neue', 'Roboto';
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.07px;
            color: #ffffff;
          }
        }

        .nav-li:hover {
          opacity: 0.7 !important;
          cursor: pointer;
        }
      }
    }

    .nav-right {
      display: flex;
      justify-content: flex-end;

      @media only screen and (max-width: 768px) {
        display: none;
      }
      @media only screen and (max-width: 992px) {
        display: none;
      }

      // .btn-connect {
      //   background: ${props => props.isBlack ? '#04E9BC' : 'transparent'};
      //   padding: 6px 15px;
      //   border-radius: 5px;
      //   // border: 1px solid #fff;
      //   font-family: "Helvetica Neue", Roboto;
      //   font-size: 14px;
      //   letter-spacing: 0.28px;
      //   color: ${props => props.isBlack ? '#04E9BC' : '#fff'};
      //   margin: 0;
      // }
      .btn-connect {
        background-color: transparent;
        padding: 6px 15px;
        border-radius: 5px;
        border: 1px solid #04E9BC;
        font-family: "Helvetica Neue", Roboto;
        font-size: 14px;
        letter-spacing: 0.28px;
        color: #04E9BC;
        margin: 0;
      }

      .btn-connect:hover {
        border-color: #04E9BC;
        opacity: 0.5;
      }
    }

    .icon-container {
      display: none;
      justify-content: flex-end;

      .icon {
        display: none;

        @media (max-width: 768px) {
          display: block;
          font-size: 25px;
          color: white;
        }
      }

      .icon-collapse {
        animation: flipInX .2s ease-in;
      }
      .icon-collapse-out {
        animation: flipOutX .2s ease-in;
      }
    }
    @media only screen and (max-width: 992px){
      .icon-container {
        display: flex;
        .icon {
          display: block;
          font-size: 25px;
          color: white;
        }
      }
    }
  }
  .flipInX {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    animation-name: flipInX;
  }

  @-webkit-keyframes flipInX {
    0% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
  
    100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }

  @keyframes flipInX {
    0% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    
    100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }

  .flipOutX {
    -webkit-animation-name: flipInX;
    animation-name: flipOutX;
  }
  @keyframes flipOutX {
    0% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    } 
  } 
  @media (max-width: 1212px) {

  }
`;

export const ModalWrapper = styled.div`
  .row-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    font-family: "Helvetica Neue", Roboto;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.18px;
    text-align: left;
    color: #333333;
  }
  // .btn-close {
  //   &:hover, &:active, &:after {
  //     border-color: transparent;
  //   }
  // }
  .icon {
    height: 40px;
    object-fit: contain;
  }
  .container-modal {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    
    .btn {
      margin-bottom: 20px;
      border: 1px sold #000;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 30px 20px;
      
      p {
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.14px;
        text-align: left;
        color: #333333;
      }
      
      img {
        height: 30px;
        width: 30px;
        object-fit: contain;
      }
      
      .disabled {
        color: rgba(51, 51, 51, 0.5);
      }
    }
  }
`;

export const MyAccountModalWrapper = styled.div`
  background-color: transparent;
  border-radius: 10px;

  .header {
    .title {
      h1 {
        font-size: 18px;
        font-weight: bold;
        color: #32737e;
      }
    }

    .close-btn {
      display: flex;
      justify-content: flex-end;
      .ant-btn {
        color: #32737e;
        border-color: #32737e;
        &:hover, &:active, &:after {
          border-color: #61a1ac;
        }
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 20px;
    border: 1px solid #32737e;
    border-radius: 10px;

    p {
      font-size: 12px;
      font-weight: normal;
      color: #32737e;
    }

    .top {
      margin-bottom: 10px;

      p {
        font-size: 14px;
        font-weight: normal;
        color: #9a9ca6;
      }
    }

    .middle {
      display: flex;
      margin-bottom: 10px;

      .account-img {
        margin-right: 10px;
      }

      p {
        font-size: 19px;
        font-weight: 500;
        color: #32737e;
      }
    }

    .bottom {
      display: flex;
      margin-top: 5px;

      .copy-container {
        display: flex;
        margin-right: 20px;

        .copy-wrapper {
          display: flex;

          .copy-clip {
            margin-right: 4px;
            color: #9a9ca6;
            font-size: 15px;
          }

          img {
            width: 12px;
            object-fit: contain;
            margin-right: 5px;

            &:hover {
              cursor: pointer;
            }
          }

          p {
            font-size: 12px;
            font-weight: normal;
            color: #9a9ca6;
          }
        }
      }

      .view-on-container {
        display: flex;

        a {
          display: flex;

          img {
            width: 12px;
            object-fit: contain;
            margin-right: 5px;
          }

          p {
            font-size: 12px;
            font-weight: normal;
            color: #9a9ca6;
          }
        }
      }
    }
  }

  .footer {
    margin-top: 20px;

    .left {
      h1 {
        font-size: 17px;
        font-weight: bold;
        color: #282828;
      }
    }

    .right {
      text-align: right;

      a {
        font-size: 14px;
        font-weight: normal;
        color: #1a62d7;
      }
    }

    .transition-container {
      .transition-row {
        margin-bottom: 10px;

        .transition-left {
          display: flex;
          align-items: center;

          h1 {
            font-size: 15px;
            font-weight: 500;
            color: #4a4e5e;
            margin-bottom: 0;
            margin-right: 10px;
          }

          img {
            width: 9px;
            object-fit: contain;
          }
        }

        .transition-right {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .error-icon {
            color: #de4437;
            font-size: 15px;
          }

          img {
            width: 14px;
            object-fit: contain;
          }
        }
      }
    }
  }
`;

export default WithDirection(NavWrapper);
