import { get } from 'lodash';
import { gql } from '@apollo/client';
import tokenAbi from 'human-standard-token-abi';

import graphqlClient from '../services/graphql';
import http from '../services/http';

export default class User {
  static fetchUserDepositIds(address, contractInstance) {
    return contractInstance.methods.getDepositsByWithdrawalAddress(address).call();
  }

  static fetchDepositDetails(id, contractInstance) {
    return contractInstance.methods.lockedToken(id).call();
  }

  static async fetchLockedTokenInfo(tokenAddress) {
    const contract = new window.web3.eth.Contract(tokenAbi, tokenAddress);
    /* Response format
       {
         tokenAddress,
         name (string),
         symbol (string),
         decimals (uint8),
         totalSupply (uint256)
       }
    */
    return Promise.all([
      contract.methods.name().call(),
      contract.methods.symbol().call(),
      contract.methods.decimals().call(),
      contract.methods.totalSupply().call()
    ]).then(data => {
      return {
        tokenAddress,
        name: get(data, '0'),
        symbol: get(data, '1'),
        decimals: get(data, '2'),
        totalSupply: get(data, '3')
      };
    })
  }

  static fetchUserTokenBalance(tokenAddress, accountAddr) {
    const contract = new window.web3.eth.Contract(tokenAbi, tokenAddress);

    return contract.methods.balanceOf(accountAddr).call().then(data => data);
  }

  static getAmountTokenAllowTransfer(tokenAddress, accountAddress, lockContractAddress) {
    const tokenContract = new window.web3.eth.Contract(tokenAbi, tokenAddress);
    return tokenContract.methods.allowance(accountAddress, lockContractAddress).call();
  }

  static approveLockToken(tokenAddress, accountAddress, amount, gasPrice, lockContractAddress, callback) {
    const tokenContract = new window.web3.eth.Contract(tokenAbi, tokenAddress);
    const tokenData = tokenContract.methods.approve(lockContractAddress, amount).encodeABI();

    const rawTransaction = {
		  value: 0,
		  gasPrice,
		  data: tokenData,
		  to: tokenAddress,
		  from: accountAddress
		};

    return window.web3.eth.sendTransaction(rawTransaction, (err, data) => callback(err, data));
  }

  static multisendToken({ tokenAddr, accountAddress, addresses1, amounts1, gasPrice, contractAbi, contractAddress, fee, callback }) {
    const multisendContract = new window.web3.eth.Contract(contractAbi, contractAddress);

    // const lockData = isOldAddress ?
    //   lockContract.methods.lockTokens(tokenAddr, amount, unlockTime).encodeABI() :
    //   lockContract.methods.lockTokens(tokenAddr, accountAddress, amount, unlockTime).encodeABI();

    const mData = multisendContract.methods.multisendToken(tokenAddr, addresses1, amounts1).encodeABI();

    const rawTransaction = {
      // value: 0,
		  value: window.web3.utils.toWei(fee, 'ether'),
      gasPrice,
		  data: mData,
		  from: accountAddress,
      gasLimit: window.web3.utils.toHex(600000),
		  to: contractAddress
    };

    return window.web3.eth.sendTransaction(rawTransaction, (err, data) => callback(err, data));
  }

  static schedulePayment({
    fee,
    amount,
    callback,
    gasPrice,
    tokenAddr,
    unlockTime,
    contractAbi,
    accountAddress,
    lockContractAddress,
    calcFeeUsingTotalSupply
  }) {
    const lockContract = new window.web3.eth.Contract(contractAbi, lockContractAddress);

    const lockData = lockContract.methods.schedulePayment(
      tokenAddr,
      amount,
      unlockTime,
      accountAddress,
      fee,
      false,
      calcFeeUsingTotalSupply
    ).encodeABI();

    const rawTransaction = {
		  value: fee,
      gasPrice,
		  data: lockData,
		  from: accountAddress,
      gasLimit: window.web3.utils.toHex(600000),
		  to: lockContractAddress
    };

    return window.web3.eth.sendTransaction(rawTransaction, (err, data) => callback(err, data));
  }

  // static getFeeForLockToken({ lockContractAddress, contractAbi, amount, tokenAddress, accountAddress }) {
  //   const lockContract = new window.web3.eth.Contract(contractAbi, lockContractAddress);

  //   return lockContract.methods.getFeeInEthForERC20(amount, tokenAddress).call({ from: accountAddress });
  // }

  static getFee({ contractAddress, contractAbi, accountAddress }) {
    const multisendContract = new window.web3.eth.Contract(contractAbi, contractAddress);

    const fee = multisendContract.methods.currentFee(accountAddress).call();
    console.log("fee 1", fee);
    return fee;
  }

  static withdrawTokens({ accountAddress, contractAbi, depositId, gasPrice, lockContractAddress, callback }) {
    const contract = new window.web3.eth.Contract(contractAbi, lockContractAddress);

    const withdrawData = contract.methods.withdrawTokens(depositId).encodeABI();

    const rawTransaction = {
		  value: 0,
      gasPrice,
		  data: withdrawData,
		  from: accountAddress,
      gasLimit: window.web3.utils.toHex(600000),
		  to: lockContractAddress
    };

    return window.web3.eth.sendTransaction(rawTransaction, (err, data) => callback(err, data));
  }

  static extendToken({ accountAddress, lockContractAddress, contractAbi, gasPrice, depositId, newUnlockTime, callback }) {
    const contract = new window.web3.eth.Contract(contractAbi, lockContractAddress);

    const extendData = contract.methods.extendLockDuration(depositId, newUnlockTime).encodeABI();

    const rawTransaction = {
      value: 0,
      gasPrice,
      data: extendData,
      from: accountAddress,
      gasLimit: window.web3.utils.toHex(600000),
      to: lockContractAddress
    };
    return window.web3.eth.sendTransaction(rawTransaction, (err, data) => callback(err, data));
  }

  static transferLockToken({ accountAddress, lockContractAddress, contractAbi, gasPrice, depositId, receiveAccountAddress, callback }) {
    const contract = new window.web3.eth.Contract(contractAbi, lockContractAddress);

    const extendData = contract.methods.transferLocks(depositId, receiveAccountAddress).encodeABI();

    const rawTransaction = {
      value: 0,
      gasPrice,
      data: extendData,
      from: accountAddress,
      gasLimit: window.web3.utils.toHex(600000),
      to: lockContractAddress
    };
    return window.web3.eth.sendTransaction(rawTransaction, (err, data) => callback(err, data));
  }

  static releaseToken({ accountAddress, contractAbi, depositId, gasPrice, lockContractAddress, callback }) {
    const contract = new window.web3.eth.Contract(contractAbi, lockContractAddress);

    const withdrawData = contract.methods.release(depositId).encodeABI();

    const rawTransaction = {
		  value: 0,
      gasPrice,
		  data: withdrawData,
		  from: accountAddress,
      gasLimit: window.web3.utils.toHex(600000),
		  to: lockContractAddress
    };

    return window.web3.eth.sendTransaction(rawTransaction, (err, data) => callback(err, data));
  }

  static fetchPairInfoBySelectedTokenAddr (id, uri) {
    return graphqlClient(uri).query({
      query: gql`
        query pair($token: String) {
          pairs(where: { token0: $token }) {
            id
            token0 {
              id
              symbol
              name
            }
            token1 {
              id
              symbol
              name
            }
            totalSupply
            reserveETH
            reserveETH
          }
        }
      `,
      variables: { token: id }
    })
  }

  static fetchLockupEventsOfUser(address, page, limit, chainId) {
    return http.get('/lockups/lockup-events', {
      headers: {
        'chain-id': chainId
      },
      params: {
        address,
        page,
        limit
      }
    })
  }
}
