import { gql } from '@apollo/client';
import tokenAbi from 'human-standard-token-abi';

import http from '../services/http';
import graphqlClient from '../services/graphql';

export default class Token {
  static fetchTotalSupplyOfToken(tokenAddress) {
    const contract = new window.web3.eth.Contract(tokenAbi, tokenAddress);

    return contract.methods.totalSupply().call().then(data => data);
  }

  static fetchTokenSymbol(tokenAddress) {
    const contract = new window.web3.eth.Contract(tokenAbi, tokenAddress);

    return contract.methods.symbol().call().then(data => data);
  }

  static fetchTokenName(tokenAddress) {
    const contract = new window.web3.eth.Contract(tokenAbi, tokenAddress);

    return contract.methods.name().call().then(data => data);
  }

  static fetchTokenDecimals(tokenAddress) {
    const contract = new window.web3.eth.Contract(tokenAbi, tokenAddress);

    return contract.methods.decimals().call().then(data => data);
  }

  static fetchTokenInfo(chainId, tokenAddress) {
    return http.get(`/tokens/${tokenAddress}`, {
      headers: {
        'chain-id': chainId
      }
    });
  }

  static fetchLockupEvents(chainId, tokenAddress) {
    return http.get(`/tokens/${tokenAddress}/lockup-events`, {
      headers: {
        'chain-id': chainId
      }
    });
  }

  static fetchTeamLockUpEvents(chainId, tokenAddress) {
    return http.get(`/lockups/${tokenAddress}/lockup-events`, {
      headers: {
        'chain-id': chainId
      }
    });
  }

  static fetchTrendingCoins(network) {
    return http.get(`/tokens/explore`, {
      headers: {
        network
      },
      params: {
        page: 1,
        limit: 100
      }
    });
  }

  static fetchUniswapTokenDateData(id, from, uri) {
    return graphqlClient(uri).query({
      query: gql`
        query dateData($token: String, $from: Int) {
          tokenDayDatas(orderBy: date, orderDirection: asc,
            where: {
              token: $token
              date_gt: $from
            }
           ) {
            id
            date
            priceUSD
            totalLiquidityToken
            totalLiquidityUSD
            totalLiquidityETH
            dailyVolumeUSD
          }
        }
      `,
      variables: { token: id, from }
    })
  }
}
