import Web3 from 'web3';
import moment from 'moment';
import { ethers } from 'ethers';
import WalletLink from 'walletlink';
import { createAction, createActions } from 'redux-actions';

import Fortmatic from 'fortmatic';
import Portis from '@portis/web3';
import detectEthereumProvider from '@metamask/detect-provider';
// import { chain, filter, find, get, isEmpty, map, uniqBy } from 'lodash';
import { chain, filter, find, get, map, uniqBy } from 'lodash';

import Api from '../api';
import { setContractAbi } from './common';
import contractAbiSource from '../resources/abi';
import { formatDepositResponse, 
  isAVAX, isBSC, isETR,
  multisenderContractAddress, 
  nameLiquidity, nameSymbolLiquidity, 
  oldContactAddress, uniswapApiUri } from '../utils/common';

/** SET USER ACCOUNTS **/
export const setUserAccounts = createAction('SET_USER_ACCOUNTS');

/* SET LOCK TOKEN PROCESS */
export const setUserLockTokenProcess = createAction('SET_USER_LOCK_TOKEN_PROCESS');

/* RESET LOCK TOKEN PROCESS */
export const resetLockTokenProcess = createAction('RESET_LOCK_TOKEN_PROCESS');

/* SET PENDING LOCK TOKEN TRANSACTION HASH */
export const setPendingLockTokenTransactionHash = createAction('SET_PENDING_LOCK_TOKEN_TRANSACTION_HASH');

/* SET VISIBLE MODAL WAITING CONFIRM */
export const setVisibleModalWaitingConfirm = createAction('SET_VISIBLE_MODAL_WAITING_CONFIRM');

/* CLEAR USER DATA ON DISCONNECT METAMASK */
export const clearUserDataOnDisconnectMetamask = createAction('CLEAR_USER_DATA_ON_DISCONNECT_METAMASK');

/* CLEAR SEARCH TOKEN FOR LOCKING */
export const clearSearchTokenForLocking = createAction('CLEAR_SEARCH_TOKEN_FOR_LOCKING');

/* CREATE TRANSACTION LOG */
export const storeTransactionLog = createAction('STORE_TRANSACTION_LOG');

/* CLEAR TRANSACTION LOGS */
export const clearTransactionLogs = createAction('CLEAR_TRANSACTION_LOGS');

/** CONNECT TO METAMASK **/
const { connectWalletRequest, connectWalletSuccess, connectWalletFail } = createActions({
  CONNECT_WALLET_REQUEST: () => { },
  CONNECT_WALLET_SUCCESS: data => ({ data }),
  CONNECT_WALLET_FAIL: error => ({ error }),
});

export const connectMetamask = () => async (dispatch) => {
  dispatch(connectWalletRequest());

  // Check metamask is install or not
  if (window.ethereum) {
    const provider = await detectEthereumProvider();
    // If the provider returned by detectEthereumProvider is not the same as
    // window.ethereum, something is overwriting it, perhaps another wallet.
    if (provider !== window.ethereum) {
      window.web3 = new Web3(provider);
    } else {
      window.web3 = new Web3(window.ethereum);
    }

    return window.ethereum.request({ method: 'eth_requestAccounts' })
      .then(async () => {
        const chainId = window.ethereum.chainId;
        const accounts = await window.web3.eth.getAccounts();
        const balance = await window.web3.eth.getBalance(accounts[0]);
        dispatch(connectWalletSuccess());
        dispatch(setUserAccounts({ accounts, balance, chainId }));
        if (isAVAX(chainId)) {
          dispatch(setContractAbi(contractAbiSource.avax));
        } else {
          dispatch(setContractAbi(contractAbiSource.current));
        }
      })
      .catch((error) => {
        dispatch(connectWalletFail(error));
      });
  }

  return new Promise((resolve, reject) => {
    const err = 'Metamask not install.';

    resolve(err);
    return dispatch(connectWalletFail(err));
  });
};

/** CONNECT TO COINBASE WALLET **/
export const connectCoinbaseWallet = () => async (dispatch) => {
  dispatch(connectWalletRequest());

  const walletLink = new WalletLink({
    appName: 'ErcSender',
    appLogoUrl: 'https://ercsender.com/favicon/favicon.jpg',
    darkMode: false,
  });

  const provider = walletLink.makeWeb3Provider('https://mainnet.infura.io/v3/05916f0f9bca48b5ad8d86da6a377c8b', 1);
  const web3 = new Web3(provider);
  window.ethereum.chainId = '0x1';
  window.web3 = web3;

  await provider.send('eth_requestAccounts');
  const chainId = window.ethereum.chainId;
  const accounts = await window.web3.eth.getAccounts();
  const balance = await window.web3.eth.getBalance(accounts[0]);
  dispatch(connectWalletSuccess());
  dispatch(setUserAccounts({ accounts, balance, chainId }));
  if (isAVAX(chainId)) {
    dispatch(setContractAbi(contractAbiSource.avax));
  } else {
    dispatch(setContractAbi(contractAbiSource.current));
  }

  return new Promise((resolve, reject) => {
    const err = 'Link failed.';

    resolve(err);
    return dispatch(connectWalletFail(err));
  });
};

/** CONNECT TO WALLET CONNECT **/
// export const connectToWalletConnect = () => async (dispatch) => {
//   dispatch(connectWalletRequest());

//     //  Create WalletConnect Provider
//   const web3Provider = new WalletConnectProvider({
//     infuraId: 'd3dbba882f6740efa7ea1db75dc913aa' // Required
//   });

//   //  Enable session (triggers QR Code modal)
//   await web3Provider.enable();

//   //  Wrap with Web3Provider from ethers.js
//   const provider = new providers.Web3Provider(web3Provider);

//   //  Create Web3
//   const web3 = new Web3(provider);

//   window.ethereum = provider;
//   window.web3 = web3;
//   const chainId = get(provider, 'provider.chainId', 1);
//   const accounts = get(provider, 'provider.accounts', []);

//   const balance = await window.web3.eth.getBalance(accounts[0]);
//   dispatch(connectWalletSuccess())
//   dispatch(setUserAccounts({ accounts, balance, chainId: Web3.utils.toHex(chainId) }));
//   dispatch(setContractAbi(contractAbiSource[chainId]));

//   return new Promise((resolve, reject) => {
//     const err = 'Link failed.';

//     resolve(err);
//     return dispatch(connectWalletFail(err));
//   });
// };

/** CONNECT TO FORTMATIC WALLET **/
export const connectToFortmaticWallet = () => async (dispatch) => {
  dispatch(connectWalletRequest());

  const chainId = '0x1';
  const fm = new Fortmatic(process.env.REACT_APP_FORTMATIC_ID, 'mainnet');

  const provider = fm.getProvider();
  window.ethereum.chainId = chainId;
  window.web3 = new Web3(provider);
  await window.web3.currentProvider.enable();

  const accounts = await window.web3.eth.getAccounts();
  const balance = await window.web3.eth.getBalance(accounts[0]);

  dispatch(connectWalletSuccess());
  dispatch(setUserAccounts({ accounts, balance, chainId }));
  if (isAVAX(chainId)) {
    dispatch(setContractAbi(contractAbiSource.avax));
  } else {
    dispatch(setContractAbi(contractAbiSource.current));
  }

  return new Promise((resolve, reject) => {
    const err = 'Link failed.';

    resolve(err);
    return dispatch(connectWalletFail(err));
  });
};

/** CONNECT TO PORTIS WALLET **/
export const connectToPortisWallet = () => async (dispatch) => {
  dispatch(connectWalletRequest());

  const chainId = '0x1';
  const portis = new Portis(process.env.REACT_APP_PORTIS_DAPP_ID, 'mainnet');

  const provider = portis.provider;
  window.ethereum.chainId = chainId;
  window.web3 = new Web3(provider);
  await portis.provider.enable();

  const accounts = await window.web3.eth.getAccounts();
  const balance = await window.web3.eth.getBalance(accounts[0]);

  dispatch(connectWalletSuccess());
  dispatch(setUserAccounts({ accounts, balance, chainId }));
  if (isAVAX(chainId)) {
    dispatch(setContractAbi(contractAbiSource.avax));
  } else {
    dispatch(setContractAbi(contractAbiSource.current));
  }

  return new Promise((resolve, reject) => {
    const err = 'Link failed.';

    resolve(err);
    return dispatch(connectWalletFail(err));
  });
};

/** FETCH LOCKED TOKENS INFO **/
const { fetchLockedTokensInfoRequest, fetchLockedTokensInfoSuccess, fetchLockedTokensInfoFail } = createActions({
  FETCH_LOCKED_TOKENS_INFO_REQUEST: () => { },
  FETCH_LOCKED_TOKENS_INFO_SUCCESS: data => ({ data }),
  FETCH_LOCKED_TOKENS_INFO_FAIL: error => ({ error }),
});

export const fetchLockedTokensInfo = (tokensAddr) => (dispatch) => {
  dispatch(fetchLockedTokensInfoRequest());

  return Promise.all(map(tokensAddr, addr => {
    return Api.User.fetchLockedTokenInfo(addr);
  })).then(response => {
      dispatch(fetchLockedTokensInfoSuccess(response));
      return response;
    })
    .catch(error => {
      dispatch(fetchLockedTokensInfoFail(error));
    });
};


/** FETCH USER LOCKED TOKENS **/
const { fetchUserLockedTokensRequest, fetchUserLockedTokensSuccess, fetchUserLockedTokensFail } = createActions({
  FETCH_USER_LOCKED_TOKENS_REQUEST: () => { },
  FETCH_USER_LOCKED_TOKENS_SUCCESS: data => ({ data }),
  FETCH_USER_LOCKED_TOKENS_FAIL: error => ({ error }),
});

export const fetchUserLockedTokens = (address) => async (dispatch, getState) => {
  dispatch(fetchUserLockedTokensRequest());

  let userAccount;
  const contractAbiData = getState().common.contractAbi;
  if (window.web3) {
    try {
      if (!address) {
        userAccount = await window.web3.eth.getAccounts();
      }

      const chainId = window.ethereum.chainId;
      const accountAddress = address || get(userAccount, '0');
      const lockContractAddress = multisenderContractAddress(chainId);

      const teamLockContract = new window.web3.eth.Contract(contractAbiData, lockContractAddress);
      const depositsIds = await Api.User.fetchUserDepositIds(accountAddress, teamLockContract);
      const depositData = await Promise.all(map(depositsIds, async id => {
        const data = await Api.User.fetchDepositDetails(id, teamLockContract);
        const formatedData = formatDepositResponse(data, id);
        return formatedData;
      }));

      const tokensAddress = chain(depositData)
        .map(item => item.tokenAddress)
        .uniq()
        .value();

      const tokensInfo = await fetchLockedTokensInfo(tokensAddress)(dispatch);
      const result = map(depositData, deposit => {
        const tokenAddress = get(deposit, 'tokenAddress', '');
        const tokenInfo = find(tokensInfo, item => item.tokenAddress.toLowerCase() === tokenAddress.toLowerCase());
        deposit.tokenInfo = tokenInfo;
        return deposit;
      });

      dispatch(fetchUserLockedTokensSuccess(result));
    } catch (error) {
      dispatch(fetchUserLockedTokensFail(error));
    }
  } else {
    dispatch(fetchUserLockedTokensFail([]));
  }
};

/** FETCH LOCKUP EVENTS OF USER **/
const { fetchLockupEventsOfUserRequest, fetchLockupEventsOfUserSuccess, fetchLockupEventsOfUserFail } = createActions({
  FETCH_LOCKUP_EVENTS_OF_USER_REQUEST: () => { },
  FETCH_LOCKUP_EVENTS_OF_USER_SUCCESS: data => ({ data }),
  FETCH_LOCKUP_EVENTS_OF_USER_FAIL: error => ({ error }),
});

export const fetchLockupEventsOfUser = (userAddress, page, limit) => (dispatch) => {
  dispatch(fetchLockupEventsOfUserRequest());

  const chainId = get(window, 'ethereum.chainId');

  return Api.User.fetchLockupEventsOfUser(userAddress, page, limit, chainId).then(({ data }) => {
    dispatch(fetchLockupEventsOfUserSuccess(data));

    return data;
  }).catch(error => {
    return dispatch(fetchLockupEventsOfUserFail(error));
  });
};

/** FETCH TOTAL SUPPLY OF TOKEN **/
const { fetchTotalSupplyOfTokenRequest, fetchTotalSupplyOfTokenSuccess, fetchTotalSupplyOfTokenFail } = createActions({
  FETCH_TOTAL_SUPPLY_OF_TOKEN_REQUEST: () => { },
  FETCH_TOTAL_SUPPLY_OF_TOKEN_SUCCESS: data => ({ data }),
  FETCH_TOTAL_SUPPLY_OF_TOKEN_FAIL: error => ({ error }),
});

export const fetchTotalSupplyOfToken = (tokenAddr) => (dispatch) => {
  dispatch(fetchTotalSupplyOfTokenRequest());

  return Api.User.fetchTotalSupplyOfToken(tokenAddr).then(response => {
    dispatch(fetchTotalSupplyOfTokenSuccess(response));

    return response;
  }).catch(error => {
    return dispatch(fetchTotalSupplyOfTokenFail(error));
  });
};

/** FETCH PAIR INFO BY SELECTED TOKEN ADDRESS **/
const { fetchPairInfoBySelectedTokenAddrRequest, fetchPairInfoBySelectedTokenAddrSuccess, fetchPairInfoBySelectedTokenAddrFail } = createActions({
  FETCH_PAIR_INFO_BY_SELECTED_TOKEN_ADDR_REQUEST: () => { },
  FETCH_PAIR_INFO_BY_SELECTED_TOKEN_ADDR_SUCCESS: data => ({ data }),
  FETCH_PAIR_INFO_BY_SELECTED_TOKEN_ADDR_FAIL: error => ({ error }),
});

export const fetchPairInfoBySelectedTokenAddr = (tokenAddr) => async (dispatch, getState) => {
  dispatch(fetchPairInfoBySelectedTokenAddrRequest());

  try {
    let totalSupply = {};
    let userTokenBalance = {};
    window.web3 = new Web3(window.web3.currentProvider);
    const chainId = window.ethereum.chainId;

    const uniswapApiUrl = uniswapApiUri(chainId);

    const [pairInfo, accounts] = await Promise.all([
      Api.User.fetchPairInfoBySelectedTokenAddr(tokenAddr, uniswapApiUrl),
      window.web3.eth.getAccounts(),
    ]);

    const pairInfoData = get(pairInfo, 'data.pairs.0', {});
    const tokenAddrPoolWithUniswap = get(pairInfoData, 'id');

    const process = [
      Api.User.fetchTotalSupplyOfToken(tokenAddr),
      Api.User.fetchUserTokenBalance(tokenAddr, accounts[0]),
    ];

    if (tokenAddrPoolWithUniswap) {
      process.push(
        Api.Token.fetchTotalSupplyOfToken(tokenAddrPoolWithUniswap),
        Api.User.fetchUserTokenBalance(tokenAddrPoolWithUniswap, accounts[0]),
        Api.Token.fetchTokenSymbol(tokenAddrPoolWithUniswap),
        Api.Token.fetchTokenName(tokenAddrPoolWithUniswap),
      );
    }

    const [founderTokenSupply, userFounderTokenBalance, founderTokenUniswapSupply, userLiquidityTokenBalance, liquiditySymbol, liquidityName] = await Promise.all(process);

    // To have liquidity, user must create pool with founder token on uniswap
    totalSupply.founder = founderTokenSupply;
    totalSupply.liquidity = founderTokenUniswapSupply;
    userTokenBalance.founder = userFounderTokenBalance;
    userTokenBalance.liquidity = userLiquidityTokenBalance;

    const result = {
      totalSupply,
      userTokenBalance,
      pairInfo: {
        ...pairInfoData,
        symbol: liquiditySymbol,
        tokenName: liquidityName,
      },
    };

    return dispatch(fetchPairInfoBySelectedTokenAddrSuccess(result));
  } catch (error) {
    dispatch(fetchPairInfoBySelectedTokenAddrFail(error));
    throw error;
  }
};

/** GET FEE **/
const { getFeeForLockRequest, getFeeForLockSuccess, getFeeForLockFail } = createActions({
  GET_FEE_FOR_LOCK_REQUEST: () => { },
  GET_FEE_FOR_LOCK_SUCCESS: data => ({ data }),
  GET_FEE_FOR_LOCK_FAIL: error => ({ error }),
});

export const getFeeForLock = (accountAddress) => async (dispatch) => {
  dispatch(getFeeForLockRequest());

  window.web3 = new Web3(window.web3.currentProvider);
  const chainId = window.ethereum.chainId;
  const contractAddress = multisenderContractAddress(chainId);
  const contractAbi = isAVAX(chainId) ? contractAbiSource.avax : contractAbiSource.current;

  if ( contractAddress ) {
    return Api.User.getFee({ contractAddress, contractAbi, accountAddress }).then(response => {
      dispatch(getFeeForLockSuccess(response));
    }).catch(error => {
      dispatch(getFeeForLockFail(error));
    });
  }
};

/** GET AMOUNT OF TOKEN ALLOW TRANSFER **/
const { getAmountTokenAllowTransferRequest, getAmountTokenAllowTransferSuccess, getAmountTokenAllowTransferFail } = createActions({
  GET_AMOUNT_TOKEN_ALLOW_TRANSFER_REQUEST: () => { },
  GET_AMOUNT_TOKEN_ALLOW_TRANSFER_SUCCESS: data => ({ data }),
  GET_AMOUNT_TOKEN_ALLOW_TRANSFER_FAIL: error => ({ error }),
});

export const getAmountTokenAllowTransfer = (tokenAddr, accountAddress, tokenDecimal) => (dispatch, getState) => {
  dispatch(getAmountTokenAllowTransferRequest());

  window.web3 = new Web3(window.web3.currentProvider);
  const chainId = window.ethereum.chainId;
  // const lockTokenProcess = getState().user.lockTokenProcess;
  // const tokenType = get(lockTokenProcess, 'tokenType');
  // const isLiquidity = tokenType === 'liquidity_token';
  const lockContractAddress = multisenderContractAddress(chainId);

  return Api.User.getAmountTokenAllowTransfer(tokenAddr, accountAddress, lockContractAddress).then(response => {
    const amount = ethers.utils.formatUnits(response, tokenDecimal);
    dispatch(getAmountTokenAllowTransferSuccess(amount));

    return amount;
  }).catch(error => {
    return dispatch(getAmountTokenAllowTransferFail(error));
  });
};

/** APPROVE TOKENS TO SEND **/
const { approveLockTokenRequest, approveLockTokenSuccess, approveLockTokenFail } = createActions({
  APPROVE_LOCK_TOKEN_REQUEST: () => { },
  APPROVE_LOCK_TOKEN_SUCCESS: data => ({ data }),
  APPROVE_LOCK_TOKEN_FAIL: error => ({ error }),
});

export const approveLockToken = (tokenAddr, accountAddress, amount) => async (dispatch, getState) => {
  dispatch(approveLockTokenRequest());

  window.web3 = new Web3(window.web3.currentProvider);
  const chainId = window.ethereum.chainId;
  const gasPrice = await window.web3.eth.getGasPrice();
  // const lockTokenProcess = getState().user.lockTokenProcess;
  // const tokenType = get(lockTokenProcess, 'tokenType');
  // const isLiquidity = tokenType === 'liquidity_token';
  const lockContractAddress = multisenderContractAddress(chainId);

  const log = {
    date: moment().valueOf(),
    type: 'approve',
    accountAddress,
    chainId,
  };

  const callback = (err, pendingTransactionHash) => {
    if (pendingTransactionHash) {
      log.transactionHash = pendingTransactionHash;
    }
  };

  return Api.User.approveLockToken(tokenAddr, accountAddress, amount, gasPrice, lockContractAddress, callback).then(async response => {
    dispatch(approveLockTokenSuccess(response));
    const balance = await window.web3.eth.getBalance(accountAddress);
    dispatch(setUserAccounts({ balance }));

    log.isError = false;
    dispatch(storeTransactionLog(log));

    return response;
  }).catch(error => {
    const errorCode = get(error, 'code');
    if (errorCode !== 4001) {
      log.isError = true;
      dispatch(storeTransactionLog(log));
    }

    return dispatch(approveLockTokenFail(error));
  });
};


/** LOCK TOKEN **/
const { lockTokenRequest, lockTokenSuccess, lockTokenFail } = createActions({
  LOCK_TOKEN_REQUEST: () => { },
  LOCK_TOKEN_SUCCESS: data => ({ data }),
  LOCK_TOKEN_FAIL: error => ({ error }),
});

export const multisendToken = ({ tokenAddr, accountAddress, addresses1, amounts1, fee }) => async (dispatch, getState) => {
  dispatch(lockTokenRequest());

  console.log("tokenAddr: ", tokenAddr);
  console.log("addresses1: ", addresses1);
  console.log("amounts1: ", amounts1);

  // return;

  window.web3 = new Web3(window.web3.currentProvider);
  const chainId = window.ethereum.chainId;
  const gasPrice = await window.web3.eth.getGasPrice();
  const contractAddress = multisenderContractAddress(chainId);

  // const isOldAddress = lockContractAddress === oldContactAddress['0x1'].toLowerCase() || lockContractAddress === oldContactAddress['0x3'].toLowerCase();
  // const isOldAddress = false;
  // const contractAbi = isOldAddress ? contractAbiSource.old : isAVAX(chainId) ? contractAbiSource.avax : contractAbiSource.current;
  const contractAbi = isAVAX(chainId) ? contractAbiSource.avax : contractAbiSource.current;

  const lockTokenProcess = getState().user.lockTokenProcess;
  const symbol = get(lockTokenProcess, 'tokenInfo.symbol');
  const amountInDecimals = get(lockTokenProcess, 'lockAmount');

  const log = {
    symbol,
    chainId,
    accountAddress,
    type: 'multisendToken',
    tokenAddress: tokenAddr,
    date: moment().valueOf(),
    amount: amountInDecimals,
    feeTaken: fee
  };

  const callback = (err, pendingTransactionHash) => {
    if (pendingTransactionHash) {
      log.transactionHash = pendingTransactionHash;
      dispatch(setPendingLockTokenTransactionHash({ transactionHash: pendingTransactionHash, showViewEtherscanModal: true }));
    }
  };

  return Api.User.multisendToken({ tokenAddr, accountAddress, addresses1, amounts1, gasPrice, contractAbi, contractAddress, fee, callback }).then(async response => {
    dispatch(lockTokenSuccess(response));
    const balance = await window.web3.eth.getBalance(accountAddress);
    dispatch(setUserAccounts({ balance }));

    log.isError = false;
    dispatch(storeTransactionLog(log));

    return response;
  }).catch(error => {
    const errorCode = get(error, 'code');
    if (errorCode !== 4001) {
      log.isError = true;
      dispatch(storeTransactionLog(log));
    }

    return dispatch(lockTokenFail(error));
  });
};

/** LOCK TOKEN **/
const { extendTokenRequest, extendTokenSuccess, extendTokenFail } = createActions({
  EXTEND_TOKEN_REQUEST: () => { },
  EXTEND_TOKEN_SUCCESS: data => ({ data }),
  EXTEND_TOKEN_FAIL: error => ({ error }),
});

export const setExtendTokenStep = createAction('SET_EXTEND_TOKEN_STEP');

export const extendToken = ({ accountAddress, depositId, symbol, newUnlockTime, tokenAddress }) => async (dispatch, getState) => {
  dispatch(extendTokenRequest());

  window.web3 = new Web3(window.web3.currentProvider);
  const chainId = window.ethereum.chainId;
  const gasPrice = await window.web3.eth.getGasPrice();
  const lockContractAddress = multisenderContractAddress(chainId);
  const contractAbi = contractAbiSource[chainId] || getState().common.contractAbi;

  const log = {
    symbol,
    amount: 0,
    chainId,
    tokenAddress,
    accountAddress,
    type: 'extendLockDuration',
    date: moment().valueOf(),
  };


  const callback = (err, pendingTransactionHash) => {
    if (pendingTransactionHash) {
      log.transactionHash = pendingTransactionHash;
    }
  };

  return Api.User.extendToken({ depositId, contractAbi, lockContractAddress, gasPrice, accountAddress, newUnlockTime, callback }).then(async response => {
    dispatch(extendTokenSuccess(response));
    log.isError = false;

    return { response, log };
  }).catch(error => {
    
    const errorCode = get(error, 'code');
    if (errorCode !== 4001) {
      log.isError = true;
      dispatch(storeTransactionLog(log));
    }
    return dispatch(extendTokenFail(error));
  });
};

const { transferLockTokenRequest, transferLockTokenSuccess, transferLockTokenFail } = createActions({
  TRANSFER_LOCK_TOKEN_REQUEST: () => { },
  TRANSFER_LOCK_TOKEN_SUCCESS: data => ({ data }),
  TRANSFER_LOCK_TOKEN_FAIL: error => ({ error }),
});

export const setTransferLockTokenStep = createAction('SET_TRANSFER_LOCK_TOKEN_STEP');

export const transferLockToken = ({ accountAddress, depositId, symbol, receiveAccountAddress, tokenAddress }) => async (dispatch, getState) => {
  dispatch(transferLockTokenRequest());

  window.web3 = new Web3(window.web3.currentProvider);
  const chainId = window.ethereum.chainId;
  const gasPrice = await window.web3.eth.getGasPrice();
  const lockContractAddress = multisenderContractAddress(chainId);
  const contractAbi = contractAbiSource[chainId] || getState().common.contractAbi;

  const log = {
    symbol,
    amount: 0,
    chainId,
    tokenAddress,
    accountAddress,
    type: 'transferLocks',
    date: moment().valueOf(),
  };


  const callback = (err, pendingTransactionHash) => {
    if (pendingTransactionHash) {
      log.transactionHash = pendingTransactionHash;
    }
  };

  return Api.User.transferLockToken({ depositId, contractAbi, lockContractAddress, gasPrice, accountAddress, receiveAccountAddress, callback }).then(async response => {
    dispatch(transferLockTokenSuccess(response));
    // const balance = await window.web3.eth.getBalance(accountAddress);
    // dispatch(setUserAccounts({ balance }));

    log.isError = false;
    // dispatch(storeTransactionLog(log));

    return { response, log };
  }).catch(error => {
    const errorCode = get(error, 'code');
    if (errorCode !== 4001) {
      log.isError = true;
      dispatch(storeTransactionLog(log));
    }
    return dispatch(transferLockTokenFail(error));
  });
};

/* SCHEDULE PAYMENT */
export const schedulePayment = ({ tokenAddr, accountAddress, amount, unlockTime }) => async (dispatch, getState) => {
  dispatch(lockTokenRequest());

  window.web3 = new Web3(window.web3.currentProvider);
  const chainId = window.ethereum.chainId;
  const gasPrice = await window.web3.eth.getGasPrice();
  const contractAbi = contractAbiSource['current'];
  const lockContractAddress = multisenderContractAddress(chainId);

  const lockTokenProcess = getState().user.lockTokenProcess;
  const symbol = get(lockTokenProcess, 'tokenInfo.symbol');
  const amountInDecimals = get(lockTokenProcess, 'lockAmount');
  const feeForLockToken = getState().user.feeForLockToken.result;
  const calcFeeUsingTotalSupply = !Boolean(feeForLockToken);

  let fee = Web3.utils.toBN(feeForLockToken);
  fee = Web3.utils.fromWei(fee.toString(), 'ether');
  fee = Web3.utils.toWei(fee);

  const log = {
    symbol,
    chainId,
    accountAddress,
    type: 'lockTokens',
    tokenAddress: tokenAddr,
    date: moment().valueOf(),
    amount: amountInDecimals,
  };

  const callback = (err, pendingTransactionHash) => {
    if (pendingTransactionHash) {
      log.transactionHash = pendingTransactionHash;
      dispatch(setPendingLockTokenTransactionHash({ transactionHash: pendingTransactionHash, showViewEtherscanModal: true }));
    }
  };

  return Api.User.schedulePayment({
    fee,
    amount,
    callback,
    tokenAddr,
    gasPrice,
    unlockTime,
    contractAbi,
    accountAddress,
    lockContractAddress,
    calcFeeUsingTotalSupply,
  }).then(async response => {
    dispatch(lockTokenSuccess(response));
    const balance = await window.web3.eth.getBalance(accountAddress);
    dispatch(setUserAccounts({ balance }));

    log.isError = false;
    dispatch(storeTransactionLog(log));

    return response;
  }).catch(error => {
    const errorCode = get(error, 'code');
    if (errorCode !== 4001) {
      log.isError = true;
      dispatch(storeTransactionLog(log));
    }

    return dispatch(lockTokenFail(error));
  });
};

/** WITHDRAW TOKENS **/
const { withdrawTokensRequest, withdrawTokensSuccess, withdrawTokensFail } = createActions({
  WITHDRAW_TOKENS_REQUEST: () => { },
  WITHDRAW_TOKENS_SUCCESS: data => ({ data }),
  WITHDRAW_TOKENS_FAIL: error => ({ error }),
});

export const withdrawTokens = ({ accountAddress, depositId, symbol, tokenAddress, amount, isOldAddress }) => async (dispatch, getState) => {
  dispatch(withdrawTokensRequest());

  window.web3 = new Web3(window.web3.currentProvider);
  const chainId = window.ethereum.chainId;
  const gasPrice = await window.web3.eth.getGasPrice();

  const contractAbi = isOldAddress ? contractAbiSource.old : isAVAX(chainId) ? contractAbiSource.avax : contractAbiSource.current;
  const lockContractAddress = isOldAddress ? oldContactAddress[chainId] : multisenderContractAddress(chainId);

  const apiFunction = Api.User.withdrawTokens;

  const log = {
    symbol,
    amount,
    chainId,
    tokenAddress,
    accountAddress,
    type: 'withdrawTokens',
    date: moment().valueOf(),
  };


  const callback = (err, pendingTransactionHash) => {
    if (pendingTransactionHash) {
      log.transactionHash = pendingTransactionHash;
    }
  };

  return apiFunction({ accountAddress, depositId, gasPrice, contractAbi, lockContractAddress, callback }).then(async response => {
    dispatch(withdrawTokensSuccess(response));
    log.isError = false;

    return { response, log };
  }).catch(error => {
    const errorCode = get(error, 'code');
    if (errorCode !== 4001) {
      log.isError = true;
      dispatch(storeTransactionLog(log));
    }
    return dispatch(withdrawTokensFail(error));
  });
};

/** FETCH INFO OF TOKEN WANT TO LOCK **/
const { fetchInfoOfTokenWantToLockRequest, fetchInfoOfTokenWantToLockSuccess, fetchInfoOfTokenWantToLockFail } = createActions({
  FETCH_INFO_OF_TOKEN_WANT_TO_LOCK_REQUEST: () => { },
  FETCH_INFO_OF_TOKEN_WANT_TO_LOCK_SUCCESS: data => ({ data }),
  FETCH_INFO_OF_TOKEN_WANT_TO_LOCK_FAIL: error => ({ error }),
});

export const fetchInfoOfTokenWantToLock = (tokenFullInfo) => async (dispatch, getState) => {
  const tokenAddr = tokenFullInfo.address;
  dispatch(fetchInfoOfTokenWantToLockRequest());

  // Uniswap graphql only works with Ethereum
  const chainId = get(window, 'ethereum.chainId') || '0x1';

  const uniswapApiUrl = uniswapApiUri(chainId);
  const tokenType = getState().user.lockTokenProcess.tokenType;
  const isLiquidity = tokenType === 'liquidity_token';

  try {
      window.web3 = new Web3(window.web3.currentProvider);

      const accounts = await window.web3.eth.getAccounts();

      const process = [
          Api.User.fetchUserTokenBalance(tokenAddr, accounts[0]),
          Api.Token.fetchTotalSupplyOfToken(tokenAddr),
          Api.Token.fetchTokenDecimals(tokenAddr),
          Api.Token.fetchTokenSymbol(tokenAddr),
          Api.Token.fetchTokenName(tokenAddr),
      ];

      if (isLiquidity) {
          if(isETR(chainId)) {
              process.push(
                  Api.Common.fetchUniswapPairInfo(tokenAddr.toLowerCase(), uniswapApiUrl),
              );
          } else if(isBSC(chainId)) {
              process.push(
                  Promise.resolve({
                      data: {
                          pair: tokenFullInfo
                      }
                  }),
              );
          }
      }

      const [userTokenBalance, totalSupply, decimals, symbol, name, uniswapPairInfo] = await Promise.all(process);
      if (!isLiquidity && (symbol === 'UNI-V2' || symbol === 'Cake-LP')) {
          return dispatch(fetchInfoOfTokenWantToLockSuccess({}));
      }
      if (isLiquidity && symbol !== 'UNI-V2' && symbol !== 'Cake-LP') {
          return {};
      }

      const tokenInfo = {
          address: tokenAddr,
          userTokenBalance,
          totalSupply,
          decimals,
          symbol,
          name,
      };

      const result = {
          tokenInfo,
          uniswapPairInfo: get(uniswapPairInfo, 'data.pair'),
      };

      return dispatch(fetchInfoOfTokenWantToLockSuccess(result));
  } catch (error) {
      dispatch(fetchInfoOfTokenWantToLockFail(error));
      throw error;
  }
};

// export const fetchInfoOfTokenWantToLock = (tokenAddr) => async (dispatch, getState) => {
//   dispatch(fetchInfoOfTokenWantToLockRequest());

//   const chainId = get(window, 'ethereum.chainId') || '0x1';

//   const uniswapApiUrl = uniswapApiUri(chainId);

//   const tokenType = getState().user.lockTokenProcess.tokenType;
//   const isLiquidity = tokenType === 'liquidity_token';

//   try {
//     window.web3 = new Web3(window.web3.currentProvider);

//     const accounts = await window.web3.eth.getAccounts();

//     const process = [
//       Api.User.fetchUserTokenBalance(tokenAddr, accounts[0]),
//       Api.Token.fetchTotalSupplyOfToken(tokenAddr),
//       Api.Token.fetchTokenDecimals(tokenAddr),
//       Api.Token.fetchTokenSymbol(tokenAddr),
//       Api.Token.fetchTokenName(tokenAddr),
//     ];

//     if (isLiquidity) {
//       process.push(
//         Api.Common.fetchUniswapPairInfo(tokenAddr.toLowerCase(), uniswapApiUrl),
//       );
//     }

//     const [userTokenBalance, totalSupply, decimals, symbol, name, uniswapPairInfo] = await Promise.all(process);

//     if (!isLiquidity && (symbol === 'UNI-V2' || symbol === 'Cake-LP')) {
//       return dispatch(fetchInfoOfTokenWantToLockSuccess({}));
//     }
//     if (isLiquidity && symbol !== 'UNI-V2' && symbol !== 'Cake-LP') {
//       return {};
//     }

//     const tokenInfo = {
//       address: tokenAddr,
//       userTokenBalance,
//       totalSupply,
//       decimals,
//       symbol,
//       name,
//     };

//     console.log("uniswapPairInfo: ", uniswapPairInfo);
//     const result = {
//       tokenInfo,
//       uniswapPairInfo: get(uniswapPairInfo, 'data.pair'),
//     };

//     return dispatch(fetchInfoOfTokenWantToLockSuccess(result));
//   } catch (error) {
//     dispatch(fetchInfoOfTokenWantToLockFail(error));
//     throw error;
//   }
// };

/** SEARCH TOKEN FOR LOCKING **/
const { searchTokenForLockingRequest, searchTokenForLockingSuccess, searchTokenForLockingFail } = createActions({
  SEARCH_TOKEN_FOR_LOCKING_REQUEST: () => { },
  SEARCH_TOKEN_FOR_LOCKING_SUCCESS: data => ({ data }),
  SEARCH_TOKEN_FOR_LOCKING_FAIL: error => ({ error }),
});

export const searchTokenForLocking = (queryString) => async (dispatch, getState) => {
  dispatch(searchTokenForLockingRequest());
  console.log("searchTokenForLocking");

  const chainId = get(window, 'ethereum.chainId') || '0x1';

  const uniswapApiUrl = uniswapApiUri(chainId);
  console.log("uniswapApiUrl: ", uniswapApiUrl);

  window.web3 = new Web3(window.web3.currentProvider);

  const tokenType = getState().user.lockTokenProcess.tokenType;
  console.log("tokenType: ", tokenType);

  const isLiquidity = tokenType === 'liquidity_token';
  const qString = Web3.utils.isAddress(queryString) ? queryString.toLowerCase() : queryString;

  if (isAVAX(chainId)) {
      const {symbol, name} = await Api.Common.searchTokenAvax(qString);
      const address = Web3.utils.toChecksumAddress(queryString);
      const result = [];
      if (symbol) {
          result.push({
              address,
              name,
              symbol,
          })
      }
      return dispatch(searchTokenForLockingSuccess(result));
  }
  try {
      const isBSC = ['0x38', '0x61'].includes(chainId);
      if (!isLiquidity) {
          console.log("Is not liquidity")
          const {symbol, name} = await Api.Common.searchTokenBsc(qString);
          const address = Web3.utils.toChecksumAddress(queryString);
          const result = [];
          if (symbol) {
              result.push({
                  address,
                  name,
                  symbol,
              })
          }
          console.log("result: ", result);
          return dispatch(searchTokenForLockingSuccess(result));

          // if (isBSC) {
          //     const {symbol, name} = await Api.Common.searchTokenBsc(qString);
          //     const address = Web3.utils.toChecksumAddress(queryString);
          //     const result = [];
          //     if (symbol) {
          //         result.push({
          //             address,
          //             name,
          //             symbol,
          //         })
          //     }
          //     return dispatch(searchTokenForLockingSuccess(result));
          // } else {
          //     console.log("Is not liquidity")
          //     const tokens = await Api.Common.searchTokenInUniswaps(qString, uniswapApiUrl);

          //     console.log("tokens search: ", tokens);
          //     const asName = get(tokens, 'data.asName', []);
          //     const asAddress = get(tokens, 'data.asAddress');
          //     const asSymbol = get(tokens, 'data.asSymbol', []);

          //     const uniqData = uniqBy([...asName, ...asAddress, ...asSymbol], 'id');
          //     const result = await Promise.all(map(uniqData, async item => {
          //         const address = get(item, 'id');
          //         const totalSupply = await Api.Token.fetchTotalSupplyOfToken(address);

          //         return {
          //             ...JSON.parse(JSON.stringify(item)),
          //             totalSupply,
          //             address: Web3.utils.toChecksumAddress(address),
          //         };
          //     }));

          //     if (isEmpty(result) && Web3.utils.isAddress(queryString)) {
          //         const [totalSupply, decimals, symbol, name] = await Promise.all([
          //             Api.Token.fetchTotalSupplyOfToken(queryString),
          //             Api.Token.fetchTokenDecimals(queryString),
          //             Api.Token.fetchTokenSymbol(queryString),
          //             Api.Token.fetchTokenName(queryString),
          //         ]);

          //         if (symbol === 'UNI-V2' || symbol === 'Cake-LP') {
          //             return dispatch(searchTokenForLockingSuccess([]));
          //         }

          //         const data = [{
          //             address: queryString,
          //             totalSupply,
          //             decimals,
          //             symbol,
          //             name,
          //         }];

          //         return dispatch(searchTokenForLockingSuccess(data));
          //     }
          //     dispatch(searchTokenForLockingSuccess(result));
          // }
      } else {
          if (isBSC) {
              const lpInfo = await Api.Common.searchTokenBscLP(qString);
              const result = [];
              if (lpInfo) {
                  result.push(lpInfo);
              }
              return dispatch(searchTokenForLockingSuccess(result));
          } else {
              const tokens = await Api.Common.searchUniswapPairs(qString, uniswapApiUrl);
              const as0 = get(tokens, 'data.as0', []);
              const as1 = get(tokens, 'data.as1');
              const asAddress = get(tokens, 'data.asAddress', []);

              const uniqData = uniqBy([...as0, ...as1, ...asAddress], 'id');

              const result = await Promise.all(map(uniqData, async item => {
                  const address = get(item, 'id');
                  const [decimals, totalSupply] = await Promise.all([
                      Api.Token.fetchTokenDecimals(address),
                      Api.Token.fetchTotalSupplyOfToken(address),
                  ]);

                  return {
                      ...JSON.parse(JSON.stringify(item)),
                      decimals,
                      totalSupply,
                      symbol: nameSymbolLiquidity(chainId),
                      name: nameLiquidity(chainId),
                      address: Web3.utils.toChecksumAddress(address),
                  };
              }));
              dispatch(searchTokenForLockingSuccess(result));
          }
      }

  } catch (error) {
      return dispatch(searchTokenForLockingFail(error));
  }
};

// export const searchTokenForLocking = (queryString) => async (dispatch, getState) => {
//   dispatch(searchTokenForLockingRequest());

//   const chainId = get(window, 'ethereum.chainId') || '0x1';

//   console.log("chainId: ", chainId);

//   const uniswapApiUrl = uniswapApiUri(chainId);

//   window.web3 = new Web3(window.web3.currentProvider);

//   const tokenType = getState().user.lockTokenProcess.tokenType;
//   const isLiquidity = tokenType === 'liquidity_token';
//   const qString = Web3.utils.isAddress(queryString) ? queryString.toLowerCase() : queryString;

//   if (isAVAX(chainId)) {
//     const { data: token0 } = await Api.Common.searchTokenAvax(qString, uniswapApiUrl);
//     const symbol = get(token0, 'data.address.smartContract.name');
//     const name = get(token0, 'data.address.smartContract.name');
//     const address = Web3.utils.toChecksumAddress(get(token0, 'data.address.hash'));

//     const result = [{
//       address,
//       name,
//       symbol,
//     }];
//     return dispatch(searchTokenForLockingSuccess(result));
//   }
//   try {
//     if (!isLiquidity) {
//       const tokens = await Api.Common.searchTokenInUniswaps(qString, uniswapApiUrl);
//       const asName = get(tokens, 'data.asName', []);
//       const asAddress = get(tokens, 'data.asAddress');
//       const asSymbol = get(tokens, 'data.asSymbol', []);

//       const uniqData = uniqBy([...asName, ...asAddress, ...asSymbol], 'id');
//       const result = await Promise.all(map(uniqData, async item => {
//         const address = get(item, 'id');
//         const totalSupply = await Api.Token.fetchTotalSupplyOfToken(address);

//         return {
//           ...JSON.parse(JSON.stringify(item)),
//           totalSupply,
//           address: Web3.utils.toChecksumAddress(address),
//         };
//       }));

//       if (isEmpty(result) && Web3.utils.isAddress(queryString)) {
//         const [totalSupply, decimals, symbol, name] = await Promise.all([
//           Api.Token.fetchTotalSupplyOfToken(queryString),
//           Api.Token.fetchTokenDecimals(queryString),
//           Api.Token.fetchTokenSymbol(queryString),
//           Api.Token.fetchTokenName(queryString),
//         ]);

//         if (symbol === 'UNI-V2' || symbol === 'Cake-LP') {
//           return dispatch(searchTokenForLockingSuccess([]));
//         }

//         const data = [{
//           address: queryString,
//           totalSupply,
//           decimals,
//           symbol,
//           name,
//         }];

//         return dispatch(searchTokenForLockingSuccess(data));
//       }

//       dispatch(searchTokenForLockingSuccess(result));
//     } else {
//       const tokens = await Api.Common.searchUniswapPairs(qString, uniswapApiUrl);
//       console.log('tokens: ');
//       const as0 = get(tokens, 'data.as0', []);
//       const as1 = get(tokens, 'data.as1');
//       const asAddress = get(tokens, 'data.asAddress', []);

//       const uniqData = uniqBy([...as0, ...as1, ...asAddress], 'id');

//       const result = await Promise.all(map(uniqData, async item => {
//         const address = get(item, 'id');
//         const [decimals, totalSupply] = await Promise.all([
//           Api.Token.fetchTokenDecimals(address),
//           Api.Token.fetchTotalSupplyOfToken(address),
//         ]);

//         return {
//           ...JSON.parse(JSON.stringify(item)),
//           decimals,
//           totalSupply,
//           symbol: nameSymbolLiquidity(chainId),
//           name: nameLiquidity(chainId),
//           address: Web3.utils.toChecksumAddress(address),
//         };
//       }));

//       dispatch(searchTokenForLockingSuccess(result));
//     }

//   } catch (error) {
//     return dispatch(searchTokenForLockingFail(error));
//   }
// };

/** UPDATE USER TOKEN BALANCE **/
const { updateUserTokenBalanceRequest, updateUserTokenBalanceSuccess, updateUserTokenBalanceFail } = createActions({
  UPDATE_USER_TOKEN_BALANCE_REQUEST: () => { },
  UPDATE_USER_TOKEN_BALANCE_SUCCESS: data => ({ data }),
  UPDATE_USER_TOKEN_BALANCE_FAIL: error => ({ error }),
});

export const updateUserTokenBalance = (accounts) => async (dispatch, getState) => {
  dispatch(updateUserTokenBalanceRequest());

  window.web3 = new Web3(window.web3.currentProvider);
  const lockTokenProcess = getState().user.lockTokenProcess;
  const tokenAdddress = get(lockTokenProcess, 'tokenInfo.address');

  const balance = await window.web3.eth.getBalance(accounts[0]);
  dispatch(setUserAccounts({ balance }));

  try {
    if (tokenAdddress) {
      const userTokenBalance = await Api.User.fetchUserTokenBalance(tokenAdddress, accounts[0]);
      return dispatch(updateUserTokenBalanceSuccess(userTokenBalance));
    }
  } catch (error) {
    return dispatch(updateUserTokenBalanceFail(error));
  }
};

/** CLEAR TRANSACTION LOGS **/
export const clearTransactionLog = (accountAddress, chainId) => async (dispatch, getState) => {
  const transactionLogs = getState().user.transactionLogs;
  const logData = transactionLogs.result;

  const filterLog = filter(logData, item => {
    return item.chainId !== chainId && get(item, 'accountAddress', '').toLowerCase() !== accountAddress.toLowerCase();
  });

  dispatch(clearTransactionLogs(filterLog));
};
