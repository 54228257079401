import styled from 'styled-components';
import WithDirection from '../../withDirection';

const FooterWrapper = styled.div`
  background-color: ${props => props.isBlack ? '#0c1228' : '#0c1228'};
  width: 100%;
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: none;
    padding: 50px 0;
    margin: auto;
    .footer-option {
      padding: 50px 0;
      @media only screen and (max-width: 768px) {
        padding: 0;
      }
    }
    @media only screen and (max-width: 575px) {
      padding: 30px 15px;
    }

    @media only screen and (min-width: 768px) {
      padding: 30px 5px;
    }

    @media only screen and (min-width: 1024px) {
      padding: 30px;
    }

    

    .footer-logo {
      display: flex;
      flex-direction: column;
      min-height: 210px;
      .logo {
        width: auto;
        height: 45px;
        object-fit: contain;
        @media only screen and (max-width: 768px) {
          height: 32px;
        }
      }
      @media only screen and (max-width: 767px) {
        min-height: 100%;
        margin-bottom: 20px;
      }
    }
    .footer-col {
      display: flex;
      flex-direction: column;
      min-height: 210px;
      .col-title {
        font-size: 18px;
        font-weight: 600;
        color: ${props => props.isBlack ? '#fff' : '#333333'};
      }
      .col-content {
        font-size: 16px;
        font-weight: 500;
        color: ${props => props.isBlack ? '#fff' : '#999999'};
        margin: 10px 0;
      }
      .col-content:hover {
        color: #687684;
      }
      .social-block {
        display: flex;
        margin: 10px 0;
        align-items: center;
        .social-icon {
          height: 17px;
          width: auto;
          margin-right: 8px;
          filter: brightness(${props => props.isBlack ? '300%' : '100%'})
        }
        .social-name {
          font-size: 16px;
          font-weight: 500;
          color: ${props => props.isBlack ? '#fff' : '#999999'};
        }
      }
      .social-block:hover {
        .social-name {
          color: #687684;
        }
      }
    }
    .footer-left {
      .logo {
        width: auto;
        height: 35px;
        object-fit: contain;
      }
    }

    .footer-center {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 576px) {
        margin-top: 20px;
        justify-content: space-around;
      }
      
      a {
        margin: 0 10px;
        color: #333333;
        font-weight: 500;
        font-size: 15px;

        @media (max-width: 576px) {
          margin: 0px;
          font-size: 13px;
        }

        @media (max-width: 768px) {
          margin: 0px 3px;
          font-size: 14px;
          text-align: center;
        }
      }

      a:hover {
        opacity: 0.5;
      }
    }

    .footer-right {
      .list-social {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;

        a {
          img {
            width: 35px;

            @media (max-width: 768px) {
              width: 29px;
            }
          }
        }
      }
    }

    .footer-info {
      display: flex;
      align-items: center;

      .left-footer-info {
        p {
          font-size: 13px;
          font-weight: 500;
          text-align: left;
          color: #04E9BC;
          margin-bottom: 0;
        }
        a {
          color: #04E9BC;
        }
      }

      .center-footer-info {
        text-align: center;
        p {
          font-size: 13px;
          font-weight: 500;
          text-align: center;
          color: #04E9BC;
          margin-bottom: 0;
        }
        a {
          color: #04E9BC;
          padding: 0 8px;
        }
      }

      .right-footer-info {
        display: flex;
        justify-content: flex-end;
        min-height: 35px;

        .select-box {
          background-color: red;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-right {
      .list-social {
        a {
          img {
            width: 29px;
          }
        }
      }
    }
  }
  
  @media (max-width: 1212px) {

  }
  
  .drop-currency {
    margin-left: 10px;
    height: 40px;
    min-width: 80px;
    background-color: rgb(243, 247, 250);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-select-arrow {
      span {
        color: #999;
        font-size: 10px;
      }
    }
  }
`;

export default WithDirection(FooterWrapper);
