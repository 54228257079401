import { handleActions } from 'redux-actions';
import moment from 'moment';

const initialState = {
  contractAbi: [],
  uniswapTokens: {
    result: [],
    error: null,
    requesting: false
  },
  ethPrice: {
    result: null,
    error: null,
    requesting: false
  },
  storeTransaction: {
    result: null,
    error: null,
    requesting: false
  },
  tokensPriceByAddress: {
    result: null,
    error: null,
    requesting: false
  },
  tokensLockedWithTeam: {
    result: [],
    meta: null,
    error: null,
    requesting: false
  },
  tokensLockedSummary: {
    result: [],
    error: null,
    time: null,
    requesting: false
  },
  lockedLiquidity: {
    result: null,
    error: null,
    time: null,
    requesting: false
  },
  summaries: {
    result: null,
    error: null,
    requesting: false
  },
  overlapMinHeight: 225,
  exchangeRate: {},
  defaultCurrency: 'usd',
  isChangeViewLockup: false
};

export const common = handleActions({
  /* SET CONTRACT ABI */
  SET_CONTRACT_ABI: (state, { payload }) => ({
    ...state,
    contractAbi: payload
  }),
  /* CHANGE_VIEW_LOCKUP */
  CHANGE_VIEW_LOCKUP: (state, { payload }) => ({
    ...state,
    isChangeViewLockup: {
      ...state.isChangeViewLockup,
      result: payload
    }
  }),

  /* FETCH CONTRACT ABI */
  FETCH_CONTRACT_ABI_REQUEST: (state) => ({
    ...state,
    contractAbi: {
      ...state.contractAbi,
      requesting: true
    }
  }),
  FETCH_CONTRACT_ABI_SUCCESS: (state, { payload }) => ({
    ...state,
    contractAbi: {
      ...state.contractAbi,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_CONTRACT_ABI_FAIL: (state, { payload }) => ({
    ...state,
    contractAbi: {
      ...state.contractAbi,
      requesting: false,
      error: payload.error
    }
  }),

  /* ETH PRICE */
  FETCH_ETH_PRICE_REQUEST: (state) => ({
    ...state,
    ethPrice: {
      ...state.ethPrice,
      requesting: true
    }
  }),
  FETCH_ETH_PRICE_SUCCESS: (state, { payload }) => ({
    ...state,
    ethPrice: {
      ...state.ethPrice,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_ETH_PRICE_FAIL: (state, { payload }) => ({
    ...state,
    ethPrice: {
      ...state.ethPrice,
      requesting: false,
      error: payload.error
    }
  }),

  /* SEARCH TOKEN IN UNISWAP */
  SEARCH_TOKEN_IN_UNISWAP_REQUEST: (state) => ({
    ...state,
    uniswapTokens: {
      ...state.uniswapTokens,
      requesting: true
    }
  }),
  SEARCH_TOKEN_IN_UNISWAP_SUCCESS: (state, { payload }) => ({
    ...state,
    uniswapTokens: {
      ...state.uniswapTokens,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  SEARCH_TOKEN_IN_UNISWAP_FAIL: (state, { payload }) => ({
    ...state,
    uniswapTokens: {
      ...state.uniswapTokens,
      requesting: false,
      error: payload
    }
  }),


  /* STORE TRANSACTION */
  STORE_TRANSACTION_REQUEST: (state) => ({
    ...state,
    storeTransaction: {
      ...state.storeTransaction,
      requesting: true
    }
  }),
  STORE_TRANSACTION_SUCCESS: (state, { payload }) => ({
    ...state,
    storeTransaction: {
      ...state.storeTransaction,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  STORE_TRANSACTION_FAIL: (state, { payload }) => ({
    ...state,
    storeTransaction: {
      ...state.storeTransaction,
      requesting: false,
      error: payload
    }
  }),

  /* FETCH TOKENS PRICE */
  FETCH_TOKENS_PRICE_BY_ADDRESS_REQUEST: (state) => ({
    ...state,
    tokensPriceByAddress: {
      ...state.tokensPriceByAddress,
      requesting: true
    }
  }),
  FETCH_TOKENS_PRICE_BY_ADDRESS_SUCCESS: (state, { payload }) => ({
    ...state,
    tokensPriceByAddress: {
      ...state.tokensPriceByAddress,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_TOKENS_PRICE_BY_ADDRESS_FAIL: (state, { payload }) => ({
    ...state,
    tokensPriceByAddress: {
      ...state.tokensPriceByAddress,
      requesting: false,
      error: payload.error
    }
  }),

  /* SEARCH TOKENS LOCKED WITH TEAM */
  SEARCH_TOKENS_LOCKED_WITH_TEAM_REQUEST: (state) => ({
    ...state,
    tokensLockedWithTeam: {
      ...state.tokensLockedWithTeam,
      requesting: true
    }
  }),
  SEARCH_TOKENS_LOCKED_WITH_TEAM_SUCCESS: (state, { payload }) => ({
    ...state,
    tokensLockedWithTeam: {
      ...state.tokensLockedWithTeam,
      requesting: false,
      result: payload.data,
      meta: payload.meta,
      error: null
    }
  }),
  SEARCH_TOKENS_LOCKED_WITH_TEAM_FAIL: (state, { payload }) => ({
    ...state,
    tokensLockedWithTeam: {
      ...state.tokensLockedWithTeam,
      requesting: false,
      error: payload
    }
  }),

  /* FETCH TOKENS LOCKED SUMMARY*/
  FETCH_TOKENS_LOCKED_SUMMARY_REQUEST: (state) => ({
    ...state,
    tokensLockedSummary: {
      ...state.tokensLockedSummary,
      requesting: true
    }
  }),
  FETCH_TOKENS_LOCKED_SUMMARY_SUCCESS: (state, { payload }) => ({
    ...state,
    tokensLockedSummary: {
      ...state.tokensLockedSummary,
      requesting: false,
      result: payload.data,
      time: moment().valueOf(),
      error: null
    }
  }),
  FETCH_TOKENS_LOCKED_SUMMARY_FAIL: (state, { payload }) => ({
    ...state,
    tokensLockedSummary: {
      ...state.tokensLockedSummary,
      requesting: false,
      error: payload
    }
  }),

  /* FETCH LOCKED LIQUIDITY VALUE */
  FETCH_LOCKED_LIQUIDITY_VALUE_REQUEST: (state) => ({
    ...state,
    lockedLiquidity: {
      ...state.lockedLiquidity,
      requesting: true
    }
  }),
  FETCH_LOCKED_LIQUIDITY_VALUE_SUCCESS: (state, { payload }) => ({
    ...state,
    lockedLiquidity: {
      ...state.lockedLiquidity,
      requesting: false,
      result: payload.data,
      time: moment().valueOf(),
      error: null
    }
  }),
  FETCH_LOCKED_LIQUIDITY_VALUE_FAIL: (state, { payload }) => ({
    ...state,
    lockedLiquidity: {
      ...state.lockedLiquidity,
      requesting: false,
      error: payload
    }
  }),

  /* FETCH LOCKED LIQUIDITY VALUE */
  FETCH_SUMMARIES_REQUEST: (state) => ({
    ...state,
    summaries: {
      ...state.summaries,
      requesting: true
    }
  }),
  FETCH_SUMMARIES_SUCCESS: (state, { payload }) => ({
    ...state,
    summaries: {
      ...state.summaries,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_SUMMARIES_FAIL: (state, { payload }) => ({
    ...state,
    summaries: {
      ...state.summaries,
      requesting: false,
      error: payload
    }
  }),
  FETCH_EXCHANGE_RATE_DATA: (state, { payload }) => ({
    ...state,
    exchangeRate: payload
  }),
  SET_DEFAULT_CURRENCY: (state, { payload }) => ({
    ...state,
    defaultCurrency: payload
  }),

  SET_OVERLAP_MIN_HEIGHT: (state, { payload }) => ({
    ...state,
    overlapMinHeight: payload
  })
}, initialState);

export default common;
