import { handleActions } from 'redux-actions';
import moment from 'moment';

const initialState = {
  lockupEvents: {
    result: [],
    error: null,
    requesting: false
  },
  tokenInfo: {
    result: null,
    error: null,
    requesting: false
  },
  trendingCoins: {
    result: [],
    error: null,
    requesting: false,
    time: null
  },
  teamLockUpEvents: {
    result: [],
    error: null,
    requesting: false,
    time: null
  },
  uniTokenDateData: {
    result: [],
    error: null,
    requesting: false,
    time: null
  }
};

export const token = handleActions({
  /* FETCH TOKEN INFO */
  FETCH_TOKEN_INFO_REQUEST: (state) => ({
    ...state,
    tokenInfo: {
      ...state.tokenInfo,
      requesting: true
    }
  }),
  FETCH_TOKEN_INFO_SUCCESS: (state, { payload }) => ({
    ...state,
    tokenInfo: {
      ...state.tokenInfo,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_TOKEN_INFO_FAIL: (state, { payload }) => ({
    ...state,
    tokenInfo: {
      ...state.tokenInfo,
      requesting: false,
      error: payload
    }
  }),

  /* FETCH LOCK UP EVENTS */
  FETCH_LOCKUP_EVENTS_REQUEST: (state) => ({
    ...state,
    lockupEvents: {
      ...state.lockupEvents,
      requesting: true
    }
  }),
  FETCH_LOCKUP_EVENTS_SUCCESS: (state, { payload }) => ({
    ...state,
    lockupEvents: {
      ...state.lockupEvents,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_LOCKUP_EVENTS_FAIL: (state, { payload }) => ({
    ...state,
    lockupEvents: {
      ...state.lockupEvents,
      requesting: false,
      error: payload.error
    }
  }),

  /* FETCH LOCK UP EVENTS */
  FETCH_TEAM_LOCK_UP_EVENTS_REQUEST: (state) => ({
    ...state,
    teamLockUpEvents: {
      ...state.teamLockUpEvents,
      requesting: true
    }
  }),
  FETCH_TEAM_LOCK_UP_EVENTS_SUCCESS: (state, { payload }) => ({
    ...state,
    teamLockUpEvents: {
      ...state.teamLockUpEvents,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_TEAM_LOCK_UP_EVENTS_FAIL: (state, { payload }) => ({
    ...state,
    teamLockUpEvents: {
      ...state.teamLockUpEvents,
      requesting: false,
      error: payload.error
    }
  }),

  // /* FETCH TRENDING COINS */
  // FETCH_TRENDING_COINS_REQUEST: (state) => ({
  //   ...state,
  //   trendingCoins: {
  //     ...state.trendingCoins,
  //     requesting: true
  //   }
  // }),
  // FETCH_TRENDING_COINS_SUCCESS: (state, { payload }) => ({
  //   ...state,
  //   trendingCoins: {
  //     ...state.trendingCoins,
  //     requesting: false,
  //     result: payload.data,
  //     time: moment().valueOf(),
  //     error: null
  //   }
  // }),
  // FETCH_TRENDING_COINS_FAIL: (state, { payload }) => ({
  //   ...state,
  //   trendingCoins: {
  //     ...state.trendingCoins,
  //     requesting: false,
  //     error: payload.error
  //   }
  // }),

  /* FETCH UNISWAP TOKEN DATE DATA */
  FETCH_UNISWAP_TOKEN_DATE_DATA_REQUEST: (state) => ({
    ...state,
    uniTokenDateData: {
      ...state.uniTokenDateData,
      requesting: true
    }
  }),
  FETCH_UNISWAP_TOKEN_DATE_DATA_SUCCESS: (state, { payload }) => ({
    ...state,
    uniTokenDateData: {
      ...state.uniTokenDateData,
      requesting: false,
      result: payload.data,
      time: moment().valueOf(),
      error: null
    }
  }),
  FETCH_UNISWAP_TOKEN_DATE_DATA_FAIL: (state, { payload }) => ({
    ...state,
    uniTokenDateData: {
      ...state.uniTokenDateData,
      requesting: false,
      error: payload
    }
  }),
}, initialState);

export default token;
