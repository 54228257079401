import { get, times, toNumber } from 'lodash';
import $ from 'jquery';
import moment from 'moment';
import numbro from 'numbro';
import Web3 from 'web3';

window.$ = $;
window.jQuery = $;

export const calcHeaderWrap = (isNotExtraHeight) => {
  const headerElement = document.getElementById('header-wrap');
  if (headerElement) {
    const heightOverlap = headerElement.clientHeight;
    if (window.innerWidth > 767) {
      $('#overlap').innerHeight(isNotExtraHeight ? heightOverlap + 20 : heightOverlap + 125);
    } else {
      $('#overlap').innerHeight(heightOverlap + 50);
    }
  }
};

export const getHeightHeaderBlock = () => {
  const heightBlockAbsolute = document.getElementsByClassName('position-absolute') && document.getElementsByClassName('position-absolute')[0].clientHeight;
  const heightHeaderWrap = document.getElementById('header-wrap') && document.getElementById('header-wrap').clientHeight;
  const heightOverlap = document.getElementById('overlap') && document.getElementById('overlap').clientHeight;
  return {
    heightBlockAbsolute,
    heightHeaderWrap,
    heightOverlap,
  };
};

export const defaultFormatDate = 'MM/DD/YYYY';

export const convertTokenAmount = ({ decimals, value = 0, toFixed = 1, noFixed = false }) => {
  let denominator = '1';
  for (let i = 0; i < decimals; i++) {
    denominator += '0';
  }

  if (noFixed) {
    return value / Number(denominator);
  }

  return fixedFloat(toPlainString(value / Number(denominator)), toFixed);
};

export const convertAmountForTransaction = (decimal, value) => {
  let denominator = '1';
  for (let i = 0; i < decimal; i++) {
    denominator += '0';
  }

  return toPlainString(value * Number(denominator));
};

export const lockupTrustScore = (days, lockAmount, totalSupply, decimals) => {
  const totalSupplyInDecimals = convertTokenAmount({ decimals, value: totalSupply });

  let time = (days / 30) / 6; //perfect time is 6 month
  time = time > 1 ? 1 : time;
  let amount = lockAmount / (0.15 * totalSupplyInDecimals);
  amount = amount > 1 ? 1 : amount;
  const score = time * amount * 100;  // In percent

  return parseFloat(score).toFixed(2);
};

export const formatDepositResponse = (data, depositId) => {
  /* @Response {
    tokenAddress (address)
    withdrawalAddress (address)
    tokenAmount (uint256)
    unlockTime (uint256)
    withdrawn (bool)
  }
*/
  return {
    tokenAddress: get(data, 'tokenAddress', ''),
    withdrawalAddress: get(data, 'withdrawalAddress'),
    tokenAmount: get(data, 'tokenAmount'),
    unlockTime: get(data, 'unlockTime'),
    withdrawn: get(data, 'withdrawn'),
    depositId,
  };
};

export const oldContactAddress = {
  // '0x1': '0xdbf72370021babafbceb05ab10f99ad275c6220a',
  // '0x3': '0x7f207d66240fbe8db3f764f6056b6be8725cc90a',
};

export const contractAddress = chainId => {
  const address = {
    '0x1': Web3.utils.toChecksumAddress('0x4755c1161C3bbA8E2497121C17bAe5b19e7DBb7f'),
    '0x5': Web3.utils.toChecksumAddress('0xc2560DE8A70E76d8915f80a32cC6320D8467045F'),
    '0xaa36a7': Web3.utils.toChecksumAddress('0xc2560DE8A70E76d8915f80a32cC6320D8467045F'),
    // BSC Mainntet
    '0x38': Web3.utils.toChecksumAddress('0xc2560DE8A70E76d8915f80a32cC6320D8467045F'),
    // '0x61': Web3.utils.toChecksumAddress('0xc769a6BF37451CCe5625aFff8390a8014287e712'),
    '0x61': Web3.utils.toChecksumAddress('0xc2560DE8A70E76d8915f80a32cC6320D8467045F'), 
    // '0x61': Web3.utils.toChecksumAddress('0xDf68aD003175883C97c10F37681613Dc6A9B278A'),
    //AVAX network
    '0xa86a': Web3.utils.toChecksumAddress('0xc2560DE8A70E76d8915f80a32cC6320D8467045F'),
    '0xa869': Web3.utils.toChecksumAddress('0xc2560DE8A70E76d8915f80a32cC6320D8467045F'),
  };
  return address[chainId] || address['0x38'];
};

export const contractUrl = chainId => {
  const address = {
    '0x1': `https://etherscan.io/address/${contractAddress('0x1')}`,
    '0x5': `https://goerli.etherscan.io/address/${contractAddress('0x5')}`,
    '0xaa36a7': `https://sepolia.etherscan.io/address/${contractAddress('0xaa36a7')}`, // sepolia
    // BSC Mainntet not available yet
    '0x38': `https://bscscan.com/address/${contractAddress('0x38')}`,
    '0x61': `https://testnet.bscscan.com/address/${contractAddress('0x61')}`,
    '0xa86a': `https://cchain.explorer.avax.network/address/${contractAddress('0xa86a')}`,
    '0xa869': `https://cchain.explorer.avax-test.network/address/${contractAddress('0xa869')}`,
  };
  return address[chainId] || address['0x1'];
};

// 0x1 is chain ID
export const multisenderContractAddress = chainId => {
  const address = {
    '0x1': contractAddress('0x1'),
    '0x5': contractAddress('0x5'),
    '0xaa36a7': contractAddress('0xaa36a7'),
    '0x38': contractAddress('0x38'),
    '0x61': contractAddress('0x61'),
    '0xa86a': contractAddress('0xa86a'),
    '0xa869': contractAddress('0xa869'),
  };
  return address[chainId] || address['0x1'];
};

// export const swaptLockContractAddr = chainId => {
//   const address = {
//     '0x1': process.env.,
//     '0x3': process.env.,
//   };
//   return address[chainId] || address['0x1'];
// };

export const etherscanApiUrl = chainId => {
  const address = {
    '0x1': process.env.REACT_APP_ETHERSCAN_MAINNET_API_URL,
    '0x3': process.env.REACT_APP_ETHERSCAN_ROPSTEN_API_URL,
    '0x4': process.env.REACT_APP_ETHERSCAN_RINKEBY_API_URL,
    '0x5': process.env.REACT_APP_ETHERSCAN_GOERLI_API_URL,
    '0xaa36a7': process.env.REACT_APP_ETHERSCAN_SEPOLIA_API_URL,
    '0x2a': process.env.REACT_APP_ETHERSCAN_KOVAN_API_URL,
  };
  return address[chainId] || address['0x1'];
};

export const uniswapApiUri = chainId => {
  const address = {
    '0x1': 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2',
    // '0x3': 'https://api.thegraph.com/subgraphs/name/pollendefi/uniswap-v2-ropsten',
    '0x5': 'https://api.thegraph.com/subgraphs/name/liqwiz/uniswap-v3-goerli',
    '0x38': 'https://api.thegraph.com/subgraphs/name/hungpq14/subgraph-temp',
    '0x61': 'https://api.thegraph.com/subgraphs/name/hungpq14/subgraph-temp',
    '0xa86a': 'https://cchain.explorer.avax.network/graphiql',
    '0xa869': 'https://cchain.explorer.avax-test.network/graphiql',
  };
  return address[chainId] || address['0x1'];
};

export const teamTokenAddress = {
  mainnet: '0x49b8165b191906c4BFF12eFc1F6bB6011fe0203D',
  ropsten: '0x845924AeaF56AA9C6a1206281253Ed18cE9C7188',
};

export const passportTokenAddress = {
  mainnet: '0x6C4522F0035bED2180B40f4c5d9DbAab64B41325',
  ropsten: '0x6C4522F0035bED2180B40f4c5d9DbAab64B41325',
};

export const etherscanNetworkUrl = chainId => {
  const address = {
    '0x1': 'https://etherscan.io',
    '0x3': 'https://ropsten.etherscan.io',
    '0x4': 'https://rinkeby.etherscan.io',
    '0x5': 'https://goerli.etherscan.io',
    '0xaa36a7': 'https://sepolia.etherscan.io',
    '0x2a': 'https://kovan.etherscan.io',
    '0x38': 'https://bscscan.com',
    '0x61': 'https://testnet.bscscan.com',
    '0xa86a': 'https://cchain.explorer.avax.network',
    '0xa869': 'https://cchain.explorer.avax-test.network',
  };
  return address[chainId] || address['0x1'];
};

export const mintWorldPancakePairAddress = '0xa87655ad47df9d833e2fef3ec14af781c0a94f7b';

export const formatCurrency = (currency, fixed = 2) => {
  if (!currency) return '0.00';
  currency = toNumber(currency);
  if (typeof currency === 'number') {
    return String(fixedFloat(currency, fixed)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    // return currency.toFixed(fixed).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }
  return currency
  // eslint-disable-next-line
    .replace(/[(a-zA-Z)\s\_\,\-]+/g, '')
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

export const formatNumber = (number, mantissa = 2) => {
  if (number === 0) {
    return numbro(Number(number)).format({ mantissa });
  }

  if (Number(number) > 100) {
    return numbro(Number(number)).format({ thousandSeparated: true, mantissa });
  }

  if (Number(number) < 1) {
    return number;
  }

  return numbro(Number(number)).format({ mantissa: 6, optionalMantissa: true });
};

//
// export const formatCurrency = (amount, decimalCount = 2, decimal = '.', thousands = ',') => {
//   try {
//     decimalCount = Math.abs(decimalCount);
//     decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
//
//     const negativeSign = amount < 0 ? '-' : '';
//
//     let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
//     let j = (i.length > 3) ? i.length % 3 : 0;
//
//     return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
//   } catch (e) {
//     return amount;
//   }
// };

export const getTimeLeft = (start, end) => {
  const dateLocked = moment.unix(start);
  const unlockDate = moment.unix(end);
  const duration = moment.duration(unlockDate.diff(moment()));
  const years = duration.get('y');
  const months = duration.get('M');
  const days = duration.get('d');
  const hours = duration.get('h');
  const minutes = duration.get('m');
  const seconds = duration.get('s');

  const yearsString = `${years} ${years > 1 ? 'Years' : 'Year'}`;
  const monthsString = `${months} ${months > 1 ? 'Months' : 'Month'}`;
  const daysString = `${days} ${days > 1 ? 'Days' : 'Day'}`;
  const hoursString = `${hours} ${hours > 1 ? 'Hours' : 'Hour'}`;
  const minutuesString = `${minutes} ${minutes > 1 ? 'Minutes' : 'Minute'}`;
  const secondsString = `${seconds} ${seconds > 1 ? 'Seconds' : 'Second'}`;

  const response = {
    timeLeft: 0,
    timeLeftString: 'No Locks',
    format: 60,
    type: 'n',
    percent: 0,
  };

  if (years) {
    response.timeLeft = years;
    response.timeLeftString = yearsString;
    response.format = 10;
    response.type = 'y';
  } else if (months) {
    response.timeLeft = months;
    response.timeLeftString = monthsString;
    response.format = 12;
    response.type = 'M';
  } else if (days) {
    response.timeLeft = days;
    response.timeLeftString = daysString;
    response.format = 30;
    response.type = 'd';
  } else if (hours) {
    response.timeLeft = hours;
    response.timeLeftString = hoursString;
    response.format = 24;
    response.type = 'h';
  } else if (minutes) {
    response.timeLeft = minutes;
    response.timeLeftString = minutuesString;
    response.format = 60;
    response.type = 'm';
  } else if (seconds) {
    response.timeLeft = seconds;
    response.timeLeftString = secondsString;
    response.format = 60;
    response.type = 'm';
  }
  response.percentLeft = unlockDate.diff(moment()) / unlockDate.diff(dateLocked) * 100;
  return response;
};

function toPlainString(num) {
  return ('' + num).replace(
    /(-?)(\d*)\.?(\d+)e([+-]\d+)/,
    function(a, b, c, d, e) {
      return e < 0 ?
        b + '0.' + Array(1 - e - c.length).join(0) + c + d :
        b + c + d + Array(e - d.length + 1).join(0);
    },
  );
}

const fixedFloat = (input, decimals) => {
  const arr = ('' + input).split('.');
  if (arr.length === 1) return decimals === 0 ? input : [input, '.', times(decimals, () => '0').join('')].join('');
  const int = arr[0];
  const max = arr[1].length;
  const dec = arr[1].substr(0, decimals > max ? max : decimals);
  return decimals === 0
    ? int
    : [int, '.', dec.length >= decimals ? dec : (dec + times(decimals - dec.length, () => '0').join(''))].join('');
};

export const shortAddress = address => {
  if (address.length < 10) return address;
  const one = address.slice(0, 6);
  const two = address.slice(address.length - 5, address.length);
  return [one, two].join('...');
};

export const isBSC = chainId => ['0x38', '0x61'].includes(chainId);
export const isETR = chainId => ['0x1', '0xaa36a7'].includes(chainId);
export const isAVAX = chainId => ['0xa86a', '0xa869'].includes(chainId);
export const isMainnet = chainId => ['0x1', '0x38', '0xa86a'].includes(chainId);
export const isTestnet = chainId => ['0xaa36a7', '0x61', '0xa869'].includes(chainId);
export const nameLiquidity = chainId => {
  if (isBSC(chainId)) {
    return 'Pancake LPs';
  }
  if (isAVAX(chainId)) {
    return 'Avalanche';
  }
  return 'Uniswap V2';
};
export const nameSymbolLiquidity = chainId => {
  if (isBSC(chainId)) {
    return 'Cake-LP';
  }
  if (isAVAX(chainId)) {
    return 'Avax';
  }
  return 'UNI-V2';
};
export const nameExplorer = chainId => {
  if (isBSC(chainId)) {
    return 'Binance Smart Chain';
  }
  if (isAVAX(chainId)) {
    return 'Avalanche';
  }
  return 'Ethereum';
};
export const nameViewOn = chainId => {
  if (isBSC(chainId)) {
    return 'BSCScan';
  }
  if (isAVAX(chainId)) {
    return 'Avax Explorer';
  }
  return 'etherescan';
};
export const tokenRootSymbols = chainId => {
  if (isBSC(chainId)) {
    return 'BNB';
  }
  if (isAVAX(chainId)) {
    return 'AVAX';
  }
  return 'ETH';
};

export const viewTokenAddressUrl = (chainId, address) => {
  const urls = {
    '0x1': `https://etherscan.io/address/${address}`,
    '0x3': `https://ropsten.etherscan.io/address/${address}`,
    '0x38': `https://bscscan.com/address/${address}`,
    '0x61': `https://testnet.bscscan.com/address/${address}`,
    '0xa86a': `https://cchain.explorer.avax.network/address/${address}`,
    '0xa869': `https://cchain.explorer.avax-test.network/address/${address}`,
  };
  return urls[chainId] || urls['0x1'];
};

export const viewTXUrl = (chainId, hash) => {
  const urls = {
    '0x1': `https://etherscan.io/tx/${hash}`,
    '0x5': `https://goerli.etherscan.io/tx/${hash}`,
    '0xaa36a7': `https://sepolia.etherscan.io/tx/${hash}`,
    '0x38': `https://bscscan.com/tx/${hash}`,
    '0x61': `https://testnet.bscscan.com/tx/${hash}`,
    '0xa86a': `https://cchain.explorer.avax.network/tx/${hash}`,
    '0xa869': `https://cchain.explorer.avax-test.network/tx/${hash}`,
  };
  return urls[chainId] || urls['0x1'];
};
