import styled from 'styled-components';
import WithDirection from '../../../withDirection';

const ModalWrapper = styled.div`
.row-between {
  border-color: #32737e;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-family: "Helvetica Neue", Roboto;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.18px;
  text-align: left;
  color: #32737e;
}
.btn-close {
  outline: none;
  border-color: #32737e;
  &:hover, &:active, &:after {
    border-color: transparent;
    border-color: #61a1ac;
  }
}
.icon {
  height: 40px;
  object-fit: contain;
}
.container-modal {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  
  .btn {
    margin-bottom: 20px;
    border: 1px sold #000;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    
    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.14px;
      text-align: left;
      color: #333333;
    }
    
    img {
      height: 30px;
      width: 30px;
      object-fit: contain;
    }
    
    .disabled {
      color: rgba(51, 51, 51, 0.5);
    }
  }
}
`;

export default WithDirection(ModalWrapper);
