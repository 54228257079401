/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { includes } from 'lodash';

import DropdownFooter from '../../Component/DropdownFooter';
import Wrapper from './styles';
import { multisenderContractAddress, etherscanNetworkUrl } from '../../utils/common';

// const { Option } = Select;
// const blackTheme = ['/ethereum'];
// const blackTheme = [];

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socials: [
        {
          visible: `${process.env.PUBLIC_URL}/assets/footer/tele@3x.png`,
          active: `${process.env.PUBLIC_URL}/assets/footer/tele@3x.png`,
          hover: `${process.env.PUBLIC_URL}/assets/footer/tele-h.png`,
          link: 'https://t.me/https://t.me/',
          target: '_blank'
        },
        {
          visible: `${process.env.PUBLIC_URL}/assets/footer/twitter@3x.png`,
          active: `${process.env.PUBLIC_URL}/assets/footer/twitter@3x.png`,
          hover: `${process.env.PUBLIC_URL}/assets/footer/twitter-h.png`,
          link: 'https://twitter.com/',
          target: '_blank'
        },
        {
          visible: `${process.env.PUBLIC_URL}/assets/footer/etherscan@3x.png`,
          active: `${process.env.PUBLIC_URL}/assets/footer/etherscan@3x.png`,
          hover: `${process.env.PUBLIC_URL}/assets/footer/etherscan-h.png`,
          link: '#',
          target: '_blank'
        },
        {
          visible: `${process.env.PUBLIC_URL}/assets/footer/uniswap@3x.png`,
          active: `${process.env.PUBLIC_URL}/assets/footer/uniswap@3x.png`,
          hover: `${process.env.PUBLIC_URL}/assets/footer/uniswap-h.png`,
          link: '#',
          target: '_self'
        },
        // {
        //   visible: `${process.env.PUBLIC_URL}/assets/footer/youtube@3x.png`,
        //   active: `${process.env.PUBLIC_URL}/assets/footer/youtube@3x.png`,
        //   hover: `${process.env.PUBLIC_URL}/assets/footer/youtube-h.png`,
        //   link: 'https://www.youtube.com/channel/UCjMZfolatlfZ1LxnP1z7wfQ',
        //   target: '_blank'
        // },
        // {
        //   visible: `${process.env.PUBLIC_URL}/assets/footer/discord@3x.png`,
        //   active: `${process.env.PUBLIC_URL}/assets/footer/discord@3x.png`,
        //   hover: `${process.env.PUBLIC_URL}/assets/footer/discord-h.png`,
        //   link: 'https://discord.com/invite/yCCj4N3',
        //   target: '_blank'
        // }
      ],
      lockContractAddress: "0x",
      etherscanUrl: "",
      chainId: window.ethereum ? window.ethereum.chainId : '0x1'
    };
  }

  async componentDidMount() {
    const {
      chainId
    } = this.state;
    const lockContractAddress = multisenderContractAddress(chainId);
    const etherscanUri = etherscanNetworkUrl(chainId);
    const etherscanUrl = `${etherscanUri}/address/${lockContractAddress}`;
    this.setState({lockContractAddress, etherscanUrl})
  }

  async componentWillMount() {
    window.ethereum && window.ethereum.on('chainChanged', async (chainId) => {
      const lockContractAddress = multisenderContractAddress(chainId);
      const etherscanUri = etherscanNetworkUrl(chainId);
      const etherscanUrl = `${etherscanUri}/address/${lockContractAddress}`;
      this.setState({lockContractAddress, etherscanUrl})
    });
  }
  
  onMouseOver = index => () => {
    const socials = this.state.socials;
    socials[index].visible = socials[index].hover;
    this.setState({ socials });
  };

  onMouseOut = index => () => {
    const socials = this.state.socials;
    socials[index].visible = socials[index].active;
    this.setState({ socials });
  };

  render() {
    // const { defaultCurrency, setDefaultCurrency } = this.props;
    // const path = window.location.pathname;
    // const isBlack = includes(blackTheme, path);
    // const { lockContractAddress, etherscanUrl } = this.state;
    const { etherscanUrl } = this.state;
    const isBlack = false;

    return (
      <Wrapper isBlack={isBlack}>
        <footer className="footer-container">
          {/* <Row type="flex" align="top" className="footer-option">
            <Col xs={{ span: 24, order: 0 }} sm={{ span: 24 }} md={{ span: 8, order: 0 }} lg={{ span: 8, order: 0 }} className="footer-logo">
              <a href="/">
                <img className="logo" src={`${process.env.PUBLIC_URL}/assets/logo/logo.png`} alt="" />
              </a>
            </Col>

            <Col xs={{ span: 12, order: 2 }} sm={{ span: 6 }} md={{ span: 4, order: 1 }} lg={{ span: 4, order: 1 }} className="footer-col">
              <p className="col-title">Platform</p>
              <a href="/" className="col-content">Home</a>
              <a href="/view-all-coins" className="col-content">All Coins</a>
              <a href="/about-us" className="col-content">About</a>
            </Col>
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 6 }} md={{ span: 4, order: 1 }} lg={{ span: 4, order: 1 }} className="footer-col">
              <p className="col-title">Lockups</p>
              <a href="/lockups" className="col-content">Create Lock</a>
              <a href="/lockups" className="col-content">My Lockups</a>
              <a href="/about-us" className="col-content">Help Locking</a>
            </Col>
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 6 }} md={{ span: 4, order: 1 }} lg={{ span: 4, order: 1 }} className="footer-col">
              <p className="col-title">Company</p>
              <a href="/terms-and-conditions" className="col-content">Term and Conditions</a>
              <a href="/privacy-policy" className="col-content">Privacy Policy</a>
              <a href="/disclaimers" className="col-content">Disclaimers</a>
              <a href="#" className="col-content">Term and Conditions</a>
              <a href="#" className="col-content">Privacy Policy</a>
              <a href="#" className="col-content">Disclaimers</a>
            </Col>
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 6 }} md={{ span: 4, order: 1 }} lg={{ span: 4, order: 1 }} className="footer-col">
              <p className="col-title">Connect</p>
              <div className="social-block">
                <img src={`${process.env.PUBLIC_URL}/assets/footer/telegram@2x.png`} alt="telegram" className="social-icon" />
                <a href="#" target='_blank' className="social-name">Telegram</a>
              </div>
              <div className="social-block">
                <img src={`${process.env.PUBLIC_URL}/assets/footer/twitter@2x.png`} alt="twitter" className="social-icon" />
                <a href="#" target='_blank' className="social-name">Twitter</a>
              </div>
              <div className="social-block">
                <img src={`${process.env.PUBLIC_URL}/assets/footer/youtube@2x.png`} alt="youtube" className="social-icon" />
                <a href="https://www.youtube.com/channel/UCjMZfolatlfZ1LxnP1z7wfQ" target='_blank' className="social-name">Youtube</a>
              </div>
              <div className="social-block">
                <img src={`${process.env.PUBLIC_URL}/assets/footer/discord@2x.png`} alt="discord" className="social-icon" />
                <a href="https://discord.com/invite/yCCj4N3" target="_blank" className="social-name">Discord</a>
              </div>
            </Col>
          </Row> */}

          <Row type="flex" align="top" className="footer-info">
            <Col xs={12} sm={8} md={8} lg={8} className="left-footer-info">
              <a href={etherscanUrl} target="_blank" className="contractAddr">Contract Address</a>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} className="center-footer-info">
              <a href="https://t.me/ercsender" target='_blank' className="social-name">
                <img src={`${process.env.PUBLIC_URL}/assets/footer/telegram@2x.png`} alt="telegram" className="social-icon" />
              </a>
              <a href="https://x.com/ercsender" target='_blank' className="social-name">
                <img src={`${process.env.PUBLIC_URL}/assets/footer/twitter@2x.png`} alt="twitter" className="social-icon" />
              </a>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} className="right-footer-info">
              <DropdownFooter />
            </Col>
          </Row>
          {/* <Divider style={{ backgroundColor: '#dddddd' }} />
          <Row type="flex" align="top" className="footer-info">
            <Col xs={24} sm={24} md={24} lg={24} className="">
              <p>© 2022 Ercmultisender All Rights Reserved.</p>
            </Col>
          </Row> */}

        </footer>
      </Wrapper>
    );
  }
}

export default connect(
  state => ({
    // defaultCurrency: state.common.defaultCurrency
  }),
  dispatch => bindActionCreators({}, dispatch)
)(Footer);
