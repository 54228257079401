/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import $ from 'jquery';
import Web3 from 'web3';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { CloseOutlined } from '@ant-design/icons';

import { Row, Col, Modal, Button, Tooltip } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { get, includes, isEmpty, filter, orderBy } from 'lodash';

import { formatCurrency, viewTXUrl, viewTokenAddressUrl, nameViewOn, tokenRootSymbols } from '../../utils/common';

import ModalSelectWallet from '../Functions/ModalSelectWallet';

import { connectMetamask, clearTransactionLog, connectCoinbaseWallet } from '../../actions/user';

import Wrapper, { MyAccountModalWrapper } from './styles';

const navData = [
  // {
  //   id: '0',
  //   value: 'Home',
  //   path: '/',
  //   icon: `${process.env.PUBLIC_URL}/assets/nav/explore@3x.png`,
  //   iconOutline: `${process.env.PUBLIC_URL}/assets/nav/explore-mobile@3x.png`,
  //   iconMobile: `${process.env.PUBLIC_URL}/assets/nav/explore-mobile@3x.png`,
  // }
  // {
  //   id: '1',
  //   value: 'About',
  //   path: '/about-us',
  //   icon: `${process.env.PUBLIC_URL}/assets/nav/about-mobile@3x.png`,
  //   iconOutline: `${process.env.PUBLIC_URL}/assets/nav/about-outline@3x.png`,
  //   iconMobile: `${process.env.PUBLIC_URL}/assets/nav/about-outline@3x.png`,
  // },
  // {
  //   id: '2',
  //   value: 'Lockups',
  //   path: '/lockups',
  //   icon: `${process.env.PUBLIC_URL}/assets/nav/lock@3x.png`,
  //   iconOutline: `${process.env.PUBLIC_URL}/assets/nav/lock-mobile@3x.png`,
  //   iconMobile: `${process.env.PUBLIC_URL}/assets/nav/lock-mobile@3x.png`,
  // },
  // {
  //   id: '4',
  //   value: 'Staking',
  //   path: '/staking',
  //   icon: `${process.env.PUBLIC_URL}/assets/nav/trust-outline-icon@3x.png`,
  //   iconOutline: `${process.env.PUBLIC_URL}/assets/nav/trust-icon@3x.png`,
  //   iconMobile: `${process.env.PUBLIC_URL}/assets/nav/locker-room-mobile@3x.png`,
  // },
];
const navWithoutOverlap = ['/create-lock', '/terms-and-conditions', '/privacy-policy', '/disclaimers', '/ethereum', '/view-all-coins', '/about-us'];

// this list page doesn't need extra 125px
const navWithoutExtraHeight = ['/lockups'];

const blackTheme = ['/ethereum'];

window.$ = $;
window.jQuery = $;

class Navigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      isToggle: false,
      visibleConnect: false,
      isVisibleMyAccoutModal: false,
    };
  }

  componentDidMount() {
    $(window.document).ready(this.updateDimensions);
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('scroll', this.handleOnScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('scroll', this.handleOnScroll, true);
  }

  handleOnScroll = (e) => {
    const scrollTop = get(e, 'target.scrollTop');
    const scrollingElement = get(e, 'target.scrollingElement.scrollTop');

    if (scrollTop || scrollingElement > 0) {
      $('.nav-fixed').removeClass('hidden');
      $('.nav-fixed').addClass('visibility');
      const percent = scrollingElement / 180 * 100;
      $('.nav-fixed').css({ opacity: `${percent > 50 ? 1 : 0}` });
    } else {
      $('.nav-fixed').removeClass('visibility');
      $('.nav-fixed').addClass('hidden');
    }
  };

  updateDimensions = () => {
    const { history } = this.props;
    const path = get(history, 'location.pathname', '');

    const withouExtraHeight = includes(navWithoutExtraHeight, path);
    const headerElement = document.getElementById('header-wrap');

    if (window.innerWidth > 414) {
      this.setState({ isToggle: false });
    }
    if (headerElement) {
      const heightOverlap = get(headerElement, 'clientHeight', 0);
      if (window.innerWidth > 767) {
        $('#overlap').innerHeight(withouExtraHeight ? heightOverlap + 20 : heightOverlap + 125);
      } else {
        $('#overlap').innerHeight(heightOverlap + 50);
      }
    }
  };

  handleNavigate = (path) => async () => {
    if (window.innerWidth <= 991) {
      this.setState({ isToggle: !this.state.isToggle });
    }

    if (path === '/staking') {
      return Modal.warning({
        title: 'Coming Soon',
        content: ''
      });
    }

    if (path.startsWith('http')) {
      return window.open(path, '_blank');
    }

    // User must login to metamask to enter these page
    // if (path === '/my-locks' || path === '/create-lock') {
    //   if (!window.ethereum) {
    //     return;
    //   }
    //
    //   const web3 = new Web3(window.web3.currentProvider);
    //   const accounts = await web3.eth.getAccounts();
    //
    //   if (isEmpty(accounts)) {
    //     return;
    //   }
    // }

    return window.location.href = path;
  };

  copyClipboard = () => {
    this.setState({ copied: !this.state.copied });
    setTimeout(() => {
      this.setState({ copied: !this.state.copied });
    }, 900);
  };

  handleClickBtnConnectWallet = () => {
    this.toggleModalSelectWallet(false);
    const { userAccountState } = this.props;
    const accounts = get(userAccountState, 'accounts');

    if (!isEmpty(accounts)) {
      this.setState({ isVisibleMyAccoutModal: true, isToggle: false });
      return accounts;
    }

    this.handleConnectMetamask();
  };

  handleConnectMetamask = (path) => {
    const { connectMetamask, history } = this.props;

    connectMetamask().then(() => {
      const { connectWalletState } = this.props;
      const isConnect = get(connectWalletState, 'isConnect');
      if (isConnect && path) {
        return history.push(path);
      }

      const connectError = get(connectWalletState, 'error');

      if (connectError) {
        const errorCode = get(connectError, 'code', '');
        if (errorCode.toString() === '-32002') {
          return alert('Please login to Metamask');
        }

        // TODO Make popup ask user install extension
        return alert('Please install Metamask extension!');
      }
    });
  };

  toggleModalSelectWallet = (value) => {
    this.setState({ visibleConnect: value });
  };

  handleOnToggle = () => {
    const { isToggle } = this.state;
    this.setState({ isToggle: !isToggle });
  };

  handleOpenModalSelectWalletOnMobile = () => {
    this.setState({ isToggle: false }, () => {
      this.toggleModalSelectWallet(true);
    });
  };

  renderNavBar = () => {
    const { history } = this.props;

    const pathname = get(history, 'location.pathname', '');
    let styling = {};

    if (window.innerWidth > 768) {
      return navData.map((ele, index) => {
        const isOpacity = pathname === ele.path || (pathname === '/' && ele.path === '/explore');
        styling = { color: isOpacity ? "#ffba08" : "#858585" };
        return (
          <div key={index} style={styling} className="nav-li">
            {/* <img className="logo" src={ele.icon} alt={ele.value} /> */}
            <a onClick={this.handleNavigate(ele.path)}>{ele.value}</a>
          </div>
        );
      });
    }

    return navData.map((ele, index) => {
      styling = { color: pathname === ele.path ? "#ffba08" : "#858585" };
      return (
        <Col style={styling} key={index} xs={6} sm={6} md={0} lg={0} className="nav-li">
          <a onClick={this.handleNavigate(ele.path)}>{ele.value.toUpperCase()}</a>
        </Col>
      );
    });
  };

  renderNavBarOutline = () => {
    const { history } = this.props;

    const pathname = get(history, 'location.pathname', '');
    let styling = {};

    if (window.innerWidth > 768) {
      return navData.map((ele, index) => {
        const isOpacity = pathname === ele.path || (pathname === '/' && ele.path === '/explore');
        styling = { color: isOpacity ? "#ffba08" : "#858585" };
        return (
          <div key={index} style={styling} className="nav-li">
            {/* <img className="logo" src={ele.iconOutline} alt={ele.value} /> */}
            <a onClick={this.handleNavigate(ele.path)}>{ele.value}</a>
          </div>
        );
      });
    }

    return navData.map((ele, index) => {
      styling = { color: pathname === ele.path ? "#ffba08" : "#858585" };
      return (
        <Col style={styling} key={index} xs={6} sm={6} md={0} lg={0} className="nav-li">
          <a onClick={this.handleNavigate(ele.path)}>{ele.value.toUpperCase()}</a>
        </Col>
      );
    });
  };

  renderNavBarMobile = () => {
    return navData.map((ele, index) => {
      return (
        <div key={index}>
          <Row onClick={this.handleNavigate(ele.path)} className="nav-li-mobile">
            <img className="logo" src={ele.iconMobile} alt="" />
            <a>{ele.value}</a>
          </Row>
        </div>
      );
    });
  };

  renderMyAccountModal = () => {
    const { copied } = this.state;
    const { userAccountState, transactionLogs, clearTransactionLog } = this.props;
    const accountAddress = get(userAccountState, 'accounts.0', '');

    const chainId = get(userAccountState, 'chainId');
    const etherscanUrl = viewTokenAddressUrl(chainId, accountAddress);
    const logData = filter(transactionLogs.result, item => {
      if(item){
        return item.chainId === chainId && get(item, 'accountAddress', '').toLowerCase() === accountAddress.toLowerCase();
      }
      return [];
    });

    const recentlyLogs = orderBy(logData, ['date'], ['desc']).slice(0, 6);

    return (
      <MyAccountModalWrapper>
        <Row type="flex" align="middle" className="header">
          <Col span={12} className="title">
            <h1>Account</h1>
          </Col>

          <Col span={12} className="close-btn">
            <Button
              className="btn-close"
              ghost
              icon={<CloseOutlined style={{ color: '#32737e', fontSize: 20, fontWeight: 'bold' }} />}
              onClick={() => this.setState({ isVisibleMyAccoutModal: false })}
            />
          </Col>
        </Row>

        <Row type="flex" align="middle" className="content">
          <Col span={24} className="top">
            <p>Connected with Metamask</p>
          </Col>

          <Col span={24} className="middle">
            <div className="account-img">
              <Jazzicon diameter={29} seed={jsNumberForAddress(accountAddress)} />
            </div>
            <p>{accountAddress.substr(0, 5)}...{accountAddress.substr(accountAddress.length - 4, accountAddress.length)}</p>
          </Col>

          <Col span={24} className="bottom">
            <section className="copy-container">
              <div className="copy-wrapper">
                <CopyToClipboard text={accountAddress} onCopy={this.copyClipboard}>
                  {copied ?
                    (
                      <Tooltip title="Copied">
                        <i className="copy-clip fa fa-check-circle-o" />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Copy Address">
                        <img src={`${process.env.PUBLIC_URL}/assets/nav/copy@3x.png`} alt="copy" />
                      </Tooltip>
                    )
                  }
                </CopyToClipboard>

                <p>Copy Address</p>
              </div>
            </section>

            <section className="view-on-container">
              <a href={etherscanUrl} target="_blank" rel="noopener noreferrer">
                <img src={`${process.env.PUBLIC_URL}/assets/nav/popout-solid@3x.png`} alt="view-on" />
                <p>View on {nameViewOn(chainId)}</p>
              </a>
            </section>
          </Col>
        </Row>

        <Row type="flex" align="middle" className="footer">
          <Col span={18} className="left">
            <h1>Recent Transactions</h1>
          </Col>

          <Col span={6} className="right">
            <div onClick={() => clearTransactionLog(accountAddress, chainId)}>
              <a href={null}>Clear All</a>
            </div>
          </Col>

          <Col span={24} className="transition-container">
            {

              recentlyLogs.map((item, i) => {
                const type = get(item, 'type');
                const amount = get(item, 'amount');
                const symbol = get(item, 'symbol');
                const isError = get(item, 'isError');
                const transactionHash = get(item, 'transactionHash');
                const viewTxUrl = viewTXUrl(chainId, transactionHash);

                let text;
                if (type === 'approve') {
                  text = 'Approved';
                } else if (type === 'lockTokens' || type === 'withdrawTokens') {
                  const action = type === 'lockTokens' ? 'Lock' : 'Withdrawn';
                  text = `${action} ${formatCurrency(amount)} ${symbol} tokens`;
                } else if (type === 'transferLocks') {
                  text = `Transfer lock`;
                } else if (type === 'extendLockDuration') {
                  text = 'Extend lock';
                } else {
                  text = type;
                }

                return (
                  <a key={i} href={viewTxUrl} target="_blank" rel="noopener noreferrer">
                    <Row key={i} type="flex" align="middle" className="transition-row">
                      <Col span={18} className="transition-left">
                        <h1>{text}</h1>
                        <img src={`${process.env.PUBLIC_URL}/assets/nav/rise-arrow@3x.png`} alt="arrow" />
                      </Col>

                      <Col span={6} className="transition-right">
                        {isError
                          ? <i className="error-icon fa fa-exclamation-circle" aria-hidden="true" />
                          : <img src={`${process.env.PUBLIC_URL}/assets/nav/ticked@3x.png`} alt="ticked" />
                        }
                      </Col>
                    </Row>
                  </a>
                );
              })
            }
          </Col>
        </Row>
      </MyAccountModalWrapper>
    );
  };

  renderNavContainer = () => {
    const { isToggle } = this.state;
    const { history, userAccountState } = this.props;
    const path = get(history, 'location.pathname', '');

    let ethBalance = get(userAccountState, 'ethBalance', 0);
    const convertedEth = Web3.utils.fromWei(String(ethBalance));
    const accountAddress = get(userAccountState, 'accounts.0', '');

    const isBlack = includes(blackTheme, path);

    const priceSymbol = window.ethereum ? tokenRootSymbols(window.ethereum.chainId) : 'ETH';

    return (
      <div>
        <div className="nav-fixed hidden">
          <Row type="flex" align="middle" className="scrolled-nav-container">
            <Col xs={12} sm={12} md={12} lg={6} className="nav-left">
              {
                !isBlack && (
                  <a href="/">
                    <img className="logo" src={`${process.env.PUBLIC_URL}/assets/logo/logo.png`} alt="" />
                  </a>
                )
              }
            </Col>

            <Col xs={0} sm={0} md={0} lg={12} className="nav-center">
              <Row type="flex" align="middle" className="nav-ul">
                {!isBlack && this.renderNavBarOutline()}
              </Row>
            </Col>

            {!accountAddress && (
              <Col xs={0} sm={0} md={10} lg={6} className="nav-right">
                <button className="btn-connect" onClick={() => this.toggleModalSelectWallet(true)}>
                  <p>Connect Wallet</p>
                </button>
              </Col>
            )}

            {accountAddress && (
              <Col xs={0} sm={0} md={10} lg={6} className="nav-right">
                <button className="btn-account-profile balance-wrapper" onClick={this.handleClickBtnConnectWallet}>
                  <p>{convertedEth > 1 ? convertedEth.substr(0, 6) : convertedEth.substr(0, 8)} {priceSymbol}</p>
                </button>

                <button className="btn-account-profile account-addr-wrapper" onClick={this.handleClickBtnConnectWallet}>
                  <p className="account-addr">{accountAddress.substr(0, 5)}...{accountAddress.substr(accountAddress.length - 4, accountAddress.length)}</p>
                  <Jazzicon diameter={18} seed={jsNumberForAddress(accountAddress)} />
                </button>
              </Col>
            )}

            <Col xs={12} sm={12} md={12} lg={0} className="icon-container">
              <a href={null} className="icon" onClick={this.handleOnToggle}>
                {
                  isToggle ? <i className="fa fa-times icon-collapse"></i> : <i className="fa fa-bars icon-collapse-out"></i>
                }
              </a>
            </Col>
          </Row>
        </div>

        <Row type="flex" align="middle" className="nav-container">
          <Col xs={10} sm={10} md={10} lg={6} className="nav-left">
            {
              !isBlack && (
                <a href="/">
                  <img className="logo" src={`${process.env.PUBLIC_URL}/assets/logo/logo.png`} alt="" />
                </a>
              )
            }
          </Col>

          <Col xs={0} sm={0} md={0} lg={12} className="nav-center">
            <Row type="flex" align="middle" className="nav-ul">
              {!isBlack && this.renderNavBar()}
            </Row>
          </Col>

          {!accountAddress && (
            <Col xs={0} sm={0} md={10} lg={6} className="nav-right">
              <button className="btn-connect" onClick={() => this.toggleModalSelectWallet(true)}>
                <p>Connect Wallet</p>
              </button>
            </Col>
          )}

          {accountAddress && (
            <Col xs={0} sm={0} md={10} lg={6} className="nav-right">
              <button className="btn-account-profile balance-wrapper" onClick={this.handleClickBtnConnectWallet}>
                <p>{convertedEth > 1 ? convertedEth.substr(0, 6) : convertedEth.substr(0, 8)} {priceSymbol}</p>
              </button>

              <button className="btn-account-profile account-addr-wrapper" onClick={this.handleClickBtnConnectWallet}>
                <p className="account-addr">{accountAddress.substr(0, 5)}...{accountAddress.substr(accountAddress.length - 4, accountAddress.length)}</p>
                <Jazzicon diameter={18} seed={jsNumberForAddress(accountAddress)} />
              </button>
            </Col>
          )}

          <Col xs={4} sm={4} md={4} lg={0} className="icon-container">
            <a href={null} className="icon" onClick={this.handleOnToggle}>
              {
                isToggle ? <i className="fa fa-times icon-collapse"></i> : <i className="fa fa-bars icon-collapse-out"></i>
              }
            </a>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const { isToggle, isVisibleMyAccoutModal } = this.state;
    const { history, userAccountState } = this.props;
    const path = get(history, 'location.pathname', '');
    const withouOverlap = includes(navWithoutOverlap, path);

    let ethBalance = get(userAccountState, 'ethBalance', 0);
    const convertedEth = Web3.utils.fromWei(String(ethBalance));
    const accountAddress = get(userAccountState, 'accounts.0', '');

    const isBlack = includes(blackTheme, path);

    const priceSymbol = window.ethereum ? tokenRootSymbols(window.ethereum.chainId) : 'ETH';

    return (
      <Wrapper isToggle={isToggle} isBlack={isBlack}>
        {this.renderNavContainer()}
        <div type="flex" align="middle" className="dropdown-menu">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <a href="/">
              <img
                className="logo"
                src={`${process.env.PUBLIC_URL}/assets/logo/logo.png`}
                alt="logo"
                style={{
                  objectFit: 'contain',
                  height: 29,
                  margin: '20px 0',
                }}
              />
            </a>
            <a href={null} onClick={this.handleOnToggle} className="icon icon-container" style={{ color: '#ccc', fontSize: 30 }}>
              {
                isToggle ? <i className="fa fa-times icon-collapse"></i> : <i className="fa fa-bars icon-collapse-out"></i>
              }
            </a>
          </div>

          {this.renderNavBarMobile()}

          {!accountAddress && (
            <button className="btn-connect-wallet" onClick={() => this.handleOpenModalSelectWalletOnMobile()}>
              Connect Wallet
            </button>
          )}

          {accountAddress && (
            <div className="profile-wrapper">
              <button className="btn-account-profile balance-wrapper" onClick={this.handleClickBtnConnectWallet}>
                <p>{convertedEth.substr(0, 6)} {priceSymbol}</p>
              </button>

              <button className="btn-account-profile account-addr-wrapper" onClick={this.handleClickBtnConnectWallet}>
                <p className="account-addr">{accountAddress.substr(0, 5)}...{accountAddress.substr(accountAddress.length - 4, accountAddress.length)}</p>
                <Jazzicon diameter={18} seed={jsNumberForAddress(accountAddress)} />
              </button>
            </div>
          )}
        </div>
        {!withouOverlap && <div id="overlap" style={{ minHeight: this.props.overlapMinHeight }} />}

        <ModalSelectWallet visibleModalSelectWallet={this.state.visibleConnect} toggleModalSelectWallet={this.toggleModalSelectWallet} />

        <Modal
          closable={false}
          visible={isVisibleMyAccoutModal}
          // onCancel={() => this.setState({ visibleConnect: false })}
          footer={null}
          className="antd-modal-content-border-15"
        >
          {this.renderMyAccountModal()}
        </Modal>
      </Wrapper>
    );
  }
}

export default connect(
  state => ({
    userAccountState: state.user.userAccount,
    transactionLogs: state.user.transactionLogs,
    connectWalletState: state.user.connectMetamask,
    overlapMinHeight: state.common.overlapMinHeight,
  }),
  dispatch => bindActionCreators({
    connectMetamask,
    clearTransactionLog,
    connectCoinbaseWallet,
  }, dispatch),
)(Navigator);
