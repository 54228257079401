import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import 'antd/dist/antd.css';
import './index.css';
import App from './AppNew';
// import * as serviceWorker from './serviceWorker';

import configureStore from './configureStore';

const initialState = {};
const { store, history, persistor } = configureStore(initialState);

ReactDOM.render(
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </PersistGate>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
const comment = document.createComment(`
                                   o88888o
                         88o     o88888888
                       o888o   o8888888888
       o888ooo        o8888o 888888888888
  ooo8888888888888   88888888888888888888
   *88888888888888o 88888888888888888888
   o8888888888888  88888888888888888888
  o8888888888888  88888888888888888888
   88888888888*  888888888888888888*
      *88888*   888888888888888*
       888888  88888888888888*
      o88888888888888888888*
    o888888888888888888888888888888888888888oo
   8888888888888888888888888888888888888888888o
 o8888888888888888888888888888888888888888888*
888888888888888888888888888888888888888888**
*8888888888888888888888888888888**
 *88888888888* 88888
  8888888888*   *8888
  8888888888     88888o
  *888888888o     88888o
   88888888888     *8888o
   *888888888888o   *8888o
    *8888888888888o   *888o
     *88888888888888    8888
       8888888888888o    *888
        888888888888      *888o
         8888888888*       *8888
         *8888888888oo      *888
          *8888888888888o
           *88888888888888o
            *888888888888888o
              88888888   8888o
               8888888 o88888
               *8888888888*
                 8888888*
                  88888888888o
                   88888888  *o
                    8888888o
                     8888888
                      8888888
                      *8888888
                      888888888oo
                      888  888888o
                     o88   88888
                     *     8888
                          o88

`);
document.body.appendChild(comment);
