import oldContractAbi from './old-contract-abi.json';
import contractAbi from './contract-abi.json';
import avaxContractAbi from './avax-abi';
import erc20Abi from './erc20.abi';
import uniswapv2Abi from './uniswapv2.abi';

export default {
  old: oldContractAbi,
  current: contractAbi,
  avax: avaxContractAbi,
  erc20: erc20Abi,
  uniswapv2: uniswapv2Abi
};
