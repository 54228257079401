import http from '../services/http';

export default class Transaction {
  static fetchTransactionsByAddress(address, page, offset) {
    return http.get('/', {
      params: {
        module: 'account',
        action: 'tokentx',
        address,
        startblock: 0,
        endblock: 99999999,
        page: 1,
        offset: 10000,
        sort: 'desc',
        apikey: process.env.REACT_APP_ETHERSCAN_API_KEY
      }
    });
  }
}
