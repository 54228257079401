import { gql } from '@apollo/client';

import http from '../services/http';
import graphqlClient from '../services/graphql';
import contractAbiSource from '../resources/abi';
import Web3 from "web3";

export default class Common {
  static fetchContractAbi(url, contractAddress) {
    return http.get(`${url}`, {
      params: {
        module: 'contract',
        action: 'getabi',
        apikey: process.env.REACT_APP_ETHERSCAN_API_KEY,
        address: contractAddress,
      },
    });
  }

  static storeTransaction(chainId, data) {
    return http.post('/transactions/store-transaction', data, {
      headers: {
        'chain-id': chainId,
      },
    });
  }

  static searchTokenAvax(id) {
      return new Promise(async resolve => {
          try {
              if (window.web3) {
                  const contract = new window.web3.eth.Contract(contractAbiSource.erc20, id);
                  const name = await contract.methods.name().call();
                  const symbol = await contract.methods.symbol().call();
                  const decimals = await contract.methods.decimals().call();
                  resolve({name, symbol, decimals});
              } else {
                  resolve({name: '', symbol: '', decimals: ''});
              }
          } catch (e) {
              resolve({name: '', symbol: '', decimals: ''});
          }
      });
  }

  static searchTokenBsc(id) {
      return new Promise(async resolve => {
          try {
              if (window.web3) {
                  const contract = new window.web3.eth.Contract(contractAbiSource.erc20, id);
                  const name = await contract.methods.name().call();
                  const symbol = await contract.methods.symbol().call();
                  const decimals = await contract.methods.decimals().call();
                  resolve({name, symbol, decimals});
              } else {
                  resolve({name: '', symbol: '', decimals: ''});
              }
          } catch (e) {
              resolve({name: '', symbol: '', decimals: ''});
          }
      });
  }

  static searchTokenBscLP(id) {
      return new Promise(async resolve => {
          try {
              if (window.web3) {
                  const lpContract = new window.web3.eth.Contract(contractAbiSource.uniswapv2, id);
                  const token0 = Web3.utils.toChecksumAddress(await lpContract.methods['token0']().call());
                  const token1 = Web3.utils.toChecksumAddress(await lpContract.methods['token1']().call());
                  const token0Contract = new window.web3.eth.Contract(contractAbiSource.erc20, token0);
                  const name0 = await token0Contract.methods.name().call();
                  const symbol0 = await token0Contract.methods.symbol().call();
                  const token1Contract = new window.web3.eth.Contract(contractAbiSource.erc20, token1);
                  const name1 = await token1Contract.methods.name().call();
                  const symbol1 = await token1Contract.methods.symbol().call();

                  resolve({
                      address: id,
                      token0: {id: token0, name: name0, symbol: symbol0},
                      token1: {id: token1, name: name1, symbol: symbol1},
                  });
              } else {
                  resolve(undefined);
              }
          } catch (e) {
              resolve(undefined);
          }
      });
  }

  static searchTokenInUniswaps(q = '', uri) {
    return graphqlClient(uri).query({
      query: gql`
        query tokens($value: String, $id: String) {
          asSymbol: tokens(where: {symbol_contains: $value}, orderBy: totalLiquidity, orderDirection: desc) {
            id
            name
            symbol
            txCount
            decimals
            derivedETH
            tradeVolume
            totalLiquidity
            tradeVolumeUSD
            untrackedVolumeUSD
          }
          asName: tokens(where: { name_contains: $value }, orderBy: totalLiquidity, orderDirection: desc) {
            id
            name
            symbol
            txCount
            decimals
            derivedETH
            tradeVolume
            totalLiquidity
            tradeVolumeUSD
            untrackedVolumeUSD
          }
          asAddress: tokens(where: {id: $id}, orderBy: totalLiquidity, orderDirection: desc) {
            id
            name
            symbol
            txCount
            decimals
            derivedETH
            tradeVolume
            totalLiquidity
            tradeVolumeUSD
            untrackedVolumeUSD
          }}
      `,
      variables: { value: q, id: q },
    });
  }

  static searchUniswapPairs(id, uri) {
    return graphqlClient(uri).query({
      query: gql`
        query pairs($tokens: [String!], $id: String) {
          as0: pairs(where: { token0_in: $tokens }) {
            id
            token0 {
              id
              symbol
              name
            }
            token1 {
              id
              symbol
              name
            }
            reserveETH
            reserveETH
          }
          as1: pairs(where: { token1_in: $tokens }) {
            id
            token0 {
              id
              symbol
              name
            }
            token1 {
              id
              symbol
              name
            }
            reserveETH
            reserveETH
          }
          asAddress: pairs(where: {id: $id}) {
            id
            token0 {
              id
              symbol
              name
            }
            token1 {
              id
              symbol
              name
            }
            reserveETH
            reserveETH
          }
        }
      `,
      variables: { tokens: [id], id },
    });
  }

  static fetchUniswapPairInfo(tokenAddr, uri) {
    return graphqlClient(uri).query({
      query: gql`
        query pair($id: String) {
          pair(id: $id) {
            id
            token0 {
              id
              symbol
              name
              derivedETH
            }
            token1 {
              id
              symbol
              name
              derivedETH
            }
            reserve0
            reserve1
            reserveUSD
          }
        }
      `,
      variables: { id: tokenAddr },
    });
  }

  static fetchEthPrice(uri) {
    return graphqlClient(uri).query({
      query: gql`
        query ethPrice {
          bundle(id: "1") {
            ethPrice
          }
        }
      `,
    });
  }

  static fetchTokensPriceByAddress(contractAddresses, currencies) {
    return http.get('https://api.coingecko.com/api/v3/simple/token_price/ethereum', {
      params: {
        contract_addresses: contractAddresses,
        vs_currencies: currencies,
      },
    });
  }

  static fetchTokensPriceFromUniswap(addresses, uri) {
    return graphqlClient(uri).query({
      query: gql`
        query tokens($tokens: [String]) {
          tokens: tokens(where: { id_in: $tokens }) {
            id
            name
            symbol
            decimals
            derivedETH
          }
        }
      `,
      variables: { tokens: addresses },
    });
  }

  static searchTokensLockedWithTeam(network, page, limit, q, sortOption) {
    return http.get('/tokens/explore', {
      headers: {
        network,
      },
      params: {
        page,
        limit,
        query: q,
        sortBy: sortOption,
      },
    });
  }

  static fetchTokensLockedSummary(chainId) {
    return http.get('/tokens/summary-tokens-locked', {
      headers: {
        'chain-id': chainId,
      },
    });
  }

  static fetchLockedLiquidityValue() {
    return http.get('/tokens/locked-liquidity', {
      headers: {
        network: 'mainnet',
      },
    });
  }

  static fetchExchangeRate() {
    return http.get('https://free.currconv.com/api/v7/convert?q=USD_EUR&compact=ultra&apiKey=c7970c02e1df4757f619');
  }

  static fetchSummaries(network) {
    return http.get('/Summaries/overview', {
      headers: {
        network,
      },
    });
  }
}
