import { handleActions } from 'redux-actions';

const initialState = {
  presses: {
    result: [],
    error: null,
    requesting: false,
    time: null
  }
};

export const pressMedia = handleActions({
  /* FETCH PRESSES */
  FETCH_PRESSES_REQUEST: (state) => ({
    ...state,
    presses: {
      ...state.presses,
      requesting: true
    }
  }),
  FETCH_PRESSES_SUCCESS: (state, { payload }) => ({
    ...state,
    presses: {
      ...state.presses,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_PRESSES_FAIL: (state, { payload }) => ({
    ...state,
    presses: {
      ...state.presses,
      requesting: false,
      error: payload
    }
  })

}, initialState);

export default pressMedia;
