import Web3 from 'web3';
import React from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { isEmpty, get } from 'lodash';
import moment from 'moment';

import './AppNew.css';
import theme from './theme';
// import Splash from './Component/Splash';

import Nav from './Containers/NewNav';
import MainRoutes from './newNavigator';
import Footer from './Containers/NewFooter';
// import AcceptCookie from './Component/AcceptCookie';

import {
  setContractAbi,
  // fetchExchangeRate
} from './actions/common';
import {
  setUserAccounts,
  resetLockTokenProcess,
  updateUserTokenBalance,
  clearSearchTokenForLocking,
  clearUserDataOnDisconnectMetamask
} from './actions/user';
import contractAbiSource from './resources/abi';
import { isAVAX } from './utils/common';
import { LoadingOutlined } from '@ant-design/icons';

const innerWidth = window.innerWidth;

export class AppNew extends React.Component {
  constructor(props) {
    super(props);
    this.time = moment().valueOf();
  }

  componentDidMount() {
    const {
      setContractAbi,
      setUserAccounts,
      // fetchExchangeRate,
      resetLockTokenProcess,
      updateUserTokenBalance,
      clearSearchTokenForLocking,
      clearUserDataOnDisconnectMetamask
    } = this.props;

    // fetchExchangeRate();

    // window.web3 && window.web3.eth.getAccounts((error, accounts) => {
    //   if (isEmpty(accounts)) {
    //     setUserAccounts({ accounts: [] });
    //   }
    // });

   if (window.ethereum) {
      window.ethereum.autoRefreshOnNetworkChange = false;
      window.ethereum.request({ method: 'eth_accounts' }).then((accounts) => {
        if (isEmpty(accounts)) {
          setUserAccounts({ accounts: [] });
        }
      });
   }

    window.ethereum && window.ethereum.on('accountsChanged', (accounts) => {
      const isFortmatic = get(window, 'web3.currentProvider.isFortmatic');
      if (isFortmatic) {
        return false;
      }

      if (isEmpty(accounts)) {
        clearUserDataOnDisconnectMetamask();
      }

      setUserAccounts({ accounts });
      if (!isEmpty(accounts)) {
        updateUserTokenBalance(accounts);
      }
    });

    window.ethereum && window.ethereum.on('chainChanged', async (chainId) => {
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();
      let balance;
      if (!isEmpty(accounts)) {
        balance = await web3.eth.getBalance(accounts[0]);
      }

      resetLockTokenProcess();
      clearSearchTokenForLocking();
      setUserAccounts({ chainId, balance });
      if (isAVAX(chainId)) {
        setContractAbi(contractAbiSource.avax);
      } else {
        setContractAbi(contractAbiSource.current);
      }
    });

    const splashScreen = document.getElementById('splash');
    const subtract = moment().add(1, 'seconds').valueOf() - this.time;
    this.timeout = setTimeout(() => {
      splashScreen.style.display = 'none';
      document.querySelector('.footer-container').style.display = 'block';
    }, subtract > 0 ? subtract : 0);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { userAccount } = this.props;
    const appHeight = window.innerHeight;
    const accounts = get(userAccount, 'accounts');

    let stying = {
      flexDirection: 'row',
      backgroundColor: '#036B6C',
      display: 'initial',
      height: '100%',
      position: 'relative'
    };
    if (innerWidth > 992) {
      stying = {
        flexDirection: 'row',
        backgroundColor: '#036B6C',
        display: 'block',
        height: '100%',
        position: 'relative',
        zIndex: 0
      };
    }

    const contentStyling = {
      width: '100%',
      height: 'auto',
      background: 'transparent',
      overflow: 'hidden'
    };

    return (
      <ThemeProvider theme={theme}>
        <div style={{ position: 'relative' }}>
          <div id="splash">
            {/* <Splash /> */}
            <LoadingOutlined style={{ fontSize: 150, color: '#285dd5' }} />
          </div>
          {/* <Layout style={{ height: appHeight }}> */}
          <Layout>
            <Layout className="cstmLayout">
              <Router>
                <Route
                  height={this.height}
                  render={props => <Nav height={this.height} {...props} />}
                />

                <Layout
                  id="root-container"
                  className="isoContentMainLayout main-container"
                  style={contentStyling}
                >
                  
                  <MainRoutes isConnectedMetamask={!isEmpty(accounts)} />

                  <Footer />
                </Layout>
              </Router>
            </Layout>

          </Layout>
        </div>

      </ThemeProvider>
    );
  }
}

export default connect(
  state => ({
    coins: state.coingecko.coins,
    userAccount: state.user.userAccount,
    contractAbi: state.common.contractAbi
  }),
  dispatch => bindActionCreators({
    setContractAbi,
    setUserAccounts,
    // fetchExchangeRate,
    resetLockTokenProcess,
    updateUserTokenBalance,
    clearSearchTokenForLocking,
    clearUserDataOnDisconnectMetamask
  }, dispatch)
)(AppNew);

